import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import './SidebarContent.css';


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "../common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
// import { color } from "echarts";

const SidebarContent = props => {
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    // for (let i = 0; i < items.length; ++i) {
    //   if (pathName === items[i].pathname) {
    //     matchingMenuItem = items[i];
    //     break;
    //   }
    // }
    if (pathName.includes("campaigns")) {
      matchingMenuItem = document.getElementById("campaigns")
    }
    if (pathName.includes("dashboard")) {
      matchingMenuItem = document.getElementById("dashboard")
    }
    if (pathName.includes("certificate")) {
      matchingMenuItem = document.getElementById("certificate")
    }
    if (pathName.includes("users")) {
      matchingMenuItem = document.getElementById("users")
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link id="dashboard" to="/dashboard" className="">
                <i className="custom-green bx bx-rocket"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li>
              <Link
                id="campaigns"
                to="/campaigns" className="">
                <i className="bx bxs-megaphone"></i>
                <span>{props.t("Campaigns")}</span>
              </Link>
            </li>
            <li>
              <Link
                id="certificate"
                to="/certificate-vault" className="">
                <i className="bx bxs-box"></i>
                <span>{props.t("Certificate Vault")}</span>
              </Link>
            </li>
            <li>
              <Link to="/users" className={"has-arrow"}>
                <> <i className="bx bxs-user" />
                  <span>{props.t("Organization")}</span>
                </>
              </Link>
              <ul aria-expanded="false">
                <li>
                  <Link to="/users" id="users">
                    <span className="d-flex align-items-center">
                      <i className="bx bxs-user-pin" />
                      <span>{props.t("User Management")}</span>
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to="/#" className="">
                <i className="green-icon bx bx-user"></i>
                <span>{props.t("Organisations")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="">
                <i className="green-icon bx bx-slider"></i>
                <span>{props.t("Tools")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="">
                <i className="green-icon bx bx-analyse"></i>
                <span>{props.t("Activity")}</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment >
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
