import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Routes, Route, useNavigate } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
import 'assets/css/style.css';

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";
import { jwtDecode } from 'jwt-decode';
import useClearableTimeout from 'hooks/useClearableTimeout';
import './assets/scss/theme.scss';
import './assets/scss/datatables.scss';
import  './assets/css/table_header.css'
import Authmiddleware from "./routes/route";
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { bsh_getUserAuthToken, STORAGE_KEY } from 'helpers/cc_browser_storage_helper';

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = () => {
  const [userAuthToken, setUserAuthToken] = useState(null);

  const selectLayoutState = (state) => state.Layout;
  const LayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      layoutType: layout.layoutType,
    })
  );

  const authToken = bsh_getUserAuthToken();
  if (authToken != userAuthToken) {
    setUserAuthToken(authToken);
  }
  const navigate = useNavigate();
  const [customSetTimeout] = useClearableTimeout();

  const removeTokenAndNavigate = () => {
    setUserAuthToken(null)
    localStorage.removeItem(STORAGE_KEY.AUTH_TOKEN);
  };

  useEffect(() => {
    if (userAuthToken) {
      try {
        const decodedToken = jwtDecode(authToken);
        const currentTime = Date.now();
        const timeUntilExpiry = decodedToken.exp * 1000 - currentTime;

        if (timeUntilExpiry > 0) {
          customSetTimeout(removeTokenAndNavigate, timeUntilExpiry);
        } else {
          removeTokenAndNavigate();
        }
      } catch (error) {
        removeTokenAndNavigate();
      }
    }
  }, [navigate, userAuthToken]);

  const {
    layoutType
  } = useSelector(LayoutProperties);

  const Layout = getLayout(layoutType);

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>}
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;
