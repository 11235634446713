import LabelWithTooltip from 'components/common/LabelWithTooltip';
import StateMap, { UsStateCodes, UsStateNameToStateCode } from 'components/common/StateMap';
import { CcCommaStringFormatter, CcPercentStringFormatter, timeFormatter } from 'helpers/utlis';
import i18n_keys from 'i18n_keys';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';

const DashboardStateMap = ({ stateData, stateDataLoading }) => {
    const { t } = useTranslation();
    const mapData = useMemo(() => {
        return UsStateCodes
            .map((st) => {
                const stData = stateData.find(s => s.state_code === st) || {
                    state_code: st,
                    total_certificates: 0,
                    avg_duration: "0.0",
                    mobile_perc: "0.0",
                    desktop_perc: "0.0",
                    tablet_perc: "0.0",
                };

                return {
                    state_code: stData.state_code,
                    value: parseInt(stData.total_certificates || 0),
                    dataLabel: {
                        header: stData.state || Object.entries(UsStateNameToStateCode).find(([_, code]) => code === st)?.[0],
                        data: [
                            [
                                {
                                    key: t(i18n_keys.SESSIONS),
                                    value: CcCommaStringFormatter(parseInt(stData.total_certificates || 0)),
                                }, {
                                    key: t(i18n_keys.AVG_DURATION),
                                    value: timeFormatter(stData.avg_duration),
                                }
                            ],
                            [
                                {
                                    key: t(i18n_keys.MOBILE),
                                    value: CcPercentStringFormatter(stData.mobile_perc),
                                },
                                {
                                    key: t(i18n_keys.DESKTOP),
                                    value: CcPercentStringFormatter(stData.desktop_perc),
                                },
                                {
                                    key: t(i18n_keys.TABLET),
                                    value: CcPercentStringFormatter(stData?.tablet_perc),
                                }
                            ]
                        ]
                    }
                }
            })
    }, [UsStateCodes, stateData]);

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col xl="12">
                        <div className="header-container">
                            <h6 className="mb-0 font-compared-data fs-16 text-black">
                                {t(i18n_keys.DASHBOARD_ACTIVITY_BY_STATE)}
                            </h6>
                            <LabelWithTooltip
                                tooltip_message={t(i18n_keys.DASHBOARD_ACTIVITY_BY_STATE_TOOLTIP_MESSAGE)}
                                target='activity-by-state'
                                is_active={true}
                            />
                        </div>
                        <StateMap mapData={mapData} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default DashboardStateMap;
