import React, { useRef } from "react";
import { MetaTags } from 'react-meta-tags';
import { Container, Row } from 'reactstrap';
import Breadcrumbs from "../../components/common/Breadcrumb";
import ConsentCertificateDetails from "components/CertificateDetails/ConsentCertificateDetails";
import { useLocation } from "react-router-dom";
import i18n_keys from "i18n_keys";
import { t } from "i18next";
import ToastAlert, { ToastAlertIconType } from "components/common/ToastAlert/ToastAlert";
import ShareAndCopyUrl from "../../components/common/ShareAndCopyUrl";

function LoggedinView() {
  const location = useLocation();
  const certificateId = location.pathname.split('/').pop() || '';
  const copyButtonRef = useRef(null);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.origin + `/certificateDetails/${certificateId}`);
    ToastAlert.fire({ icon: ToastAlertIconType.OK, title: "URL copied to clipboard." });
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{t(i18n_keys.CERTIFICATE_VAULT) + t(i18n_keys.PAGE_TITLE_SUFFIX)}</title>
        </MetaTags>
        <Container fluid>
          <div style={{ position: 'relative', display: 'flex', alignItems: 'flex-start' }}>
            <Breadcrumbs title="" breadcrumbItem="Certificate Vault" link={"/certificate-vault"} backOption={true} />
            <ShareAndCopyUrl
              copyButtonRef={copyButtonRef}
              copyToClipboard={copyToClipboard}
              tooltipMessage={t(i18n_keys.COPY_CERTIFICATE_URLTOOLTIP_MESSAGE)}
            />
          </div>
          <Row>
            <ConsentCertificateDetails consent_certificate_id={certificateId} />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LoggedinView;
