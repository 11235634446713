import PropTypes from "prop-types"
import { React, useEffect, useState } from "react"
import { Col, Modal, Row, Form, Label, Input, FormFeedback } from "reactstrap"
import { updateUser } from "../../store/accounts/actions"
import { useDispatch } from "react-redux"
import * as Yup from "yup"
import { useFormik } from "formik"
import * as CONSTANTS from "../../common/utils/data_validations"
import { useSelector } from "react-redux"

const EditUserModal = ({ user, show, onCloseClick }) => {

  const dispatch = useDispatch()
  const [userState, setUserState] = useState(null);
  const [existUserEmail, setExistUserEmail] = useState(false)

  const { users } = useSelector(state => ({
    users: state.accounts.users,
  }))

  useEffect(() => {
    if (users) {
      const exUserEmail = users?.filter(usr => usr?.user?.email === userState?.email && user?.email !== userState?.email) || [];
      if (exUserEmail.length > 0) {
        setExistUserEmail(true)
      } else {
        if (existUserEmail != false) {
          setExistUserEmail(false)
        }
      }
    }
  }, [userState?.email])

  useEffect(() => {
    if (show && ((userState == null) || (userState?.email !== user?.email)))
    {
      setUserState(user)
    }
  }, [show, user])

  const handleChange = (e) => {
    setUserState(prevState => {
      return { ...prevState, [e.target.name]: e.target.value }
    })
  }

  const onCloseClickModal = () => {
    onCloseClick()
    setUserState(null)
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: userState?.first_name,
      last_name: userState?.last_name,
      email: userState?.email,
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(CONSTANTS.STR_INVALID_EMAIL),
      first_name: Yup.string()
        .required(CONSTANTS.STR_REQUIRED_FIRSTNAME)
        .matches(
          CONSTANTS.nameRegExp, 
          CONSTANTS.STR_INVALID_FIRST_NAME)
        .min(2),
      last_name: Yup.string()
        .required(CONSTANTS.STR_REQUIRED_LASTNAME)
        .matches(
          CONSTANTS.nameRegExp,
          CONSTANTS.STR_INVALID_LAST_NAME
        )
        .min(2)
    }),
    onSubmit: values => {
      dispatch(updateUser({...userState, first_name: values.first_name, last_name: values.last_name}))
      onCloseClickModal();
      validation.handleReset()
    },
  })

  return (
    <Modal isOpen={show} toggle={onCloseClickModal} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title text-black mt-0" id="myModalLabel">
          Edit User
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onCloseClickModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form
        onSubmit={e => {
          e.preventDefault();
          validation.handleSubmit()
          return false
        }}
      >
        <div className="modal-body noscrollbar">
          <Row>
            <Col xl={6}>
              <div className="mb-3">
                <Label className="form-label">First Name</Label>
                <Input
                  type="text"
                  name="first_name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.first_name || ""}
                  invalid={
                    validation.touched.first_name &&
                    validation.errors.first_name
                      ? true
                      : false
                  }
                ></Input>
                {validation.touched.first_name &&
                  validation.errors.first_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.first_name}
                    </FormFeedback>
                  ) : null}
              </div>
            </Col>
            <Col xl={6}>
              <div className="mb-3">
                <Label className="form-label">Last Name</Label>
                <Input
                  type="text"
                  name="last_name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.last_name || ""}
                  invalid={
                    validation.touched.last_name &&
                    validation.errors.last_name
                      ? true
                      : false
                  }
                ></Input>
                {validation.touched.last_name &&
                  validation.errors.last_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.last_name}
                    </FormFeedback>
                  ) : null}
              </div>
            </Col>
            <Col xl={12}>
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  type="email"
                  className={`form-control ${existUserEmail ? "is-invalid" : ""}`}
                  name="email"
                  id=""
                  value={userState?.email}
                  onChange={handleChange}
                  placeholder=""
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            name="close"
            onClick={onCloseClickModal}
            className="btn btn-light "
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            name="submit"
            className={`btn btn-primary`}
            disabled={existUserEmail}
          >
            Update
          </button>
        </div>
      </Form>
    </Modal>
  )
}

EditUserModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  user: PropTypes.any
}

export default EditUserModal
