import { getPrimaryTextClass } from "helpers/utlis"
import PropTypes from "prop-types"
import { React } from "react"
import { Col, Modal, Row, Form, Label } from "reactstrap"

const DeleteUserModal = ({ user, onDeleteClick, onCloseClick }) => {

  return (
    <Modal isOpen={user.active} toggle={onCloseClick} centered={true}>
      <div className="modal-header">
        <div className="d-flex justify-content-center">
          <i className="align-self-center text-primary label-title bx bxs-error me-1"></i>
          <h5 className="modal-title mt-0" id="myModalLabel">
            Delete User
          </h5>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onCloseClick}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form>
        <div className="modal-body noscrollbar">
          <Row>
            <Col xl={12}>
              <div className="mb-3">
                <Label className="form-label text-muted mb-3">
                  Are you sure you want to delete this user?
                </Label>
              </div>
              <div className="mb-3 text-black">
                <span>Name: <b>{(user?.first_name || "") + " " + (user?.last_name || "")}</b></span><br />
                <span>Email: <b>{user?.email || ""}</b></span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className={`btn btn-primary`}
            onClick={onDeleteClick}
          >
            Delete
          </button>
          <button
            type="button"
            onClick={onCloseClick}
            className="btn btn-light "
            data-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </Form>
    </Modal>
  )
}

DeleteUserModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  user: PropTypes.any,
}

export default DeleteUserModal
