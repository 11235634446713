import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { addMinutes, addWeeks, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { REPORTING_TIMEZONE, TIMEFRAME_TYPES } from './constants';
import _ from 'lodash';

export const tooltipForColumnHeader = (column, colIndex, { sortElement, filterElement }, tooltipName, columnTitle, tooltipdescription) => {
  const formattedTitle = { __html: columnTitle }
  return (
    <div className="d-flex align-items-top" style={column?.sort ? { cursor: 'pointer' } : {}}>
      <span className="me-1 text-center text-black" dangerouslySetInnerHTML={formattedTitle}></span>
      <div className='d-flex align-items-center header-cell-parent'>
        <div>
          {sortElement}
        </div>
        <i id={`tooltip-${tooltipName}-${colIndex}`} className='ms-1 bx bx-info-circle text-black bx-xs'></i>
        <UncontrolledTooltip placement="top" target={`tooltip-${tooltipName}-${colIndex}`}>
          {tooltipdescription}
        </UncontrolledTooltip>
        {filterElement}
      </div>
    </div>
  );
}

export const columnHeaderFormatter = (column, colIndex, { sortElement, filterElement }, columnTitle) => {
  const formattedTitle = { __html: columnTitle }
  return (
    <div className="d-flex align-items-end" style={column?.sort ? { cursor: 'pointer' } : {}}>
      <span className="me-1 text-center text-black" dangerouslySetInnerHTML={formattedTitle}></span>
      <div className='d-flex align-items-center header-cell-parent'>
        <div>
          {sortElement}
        </div>
        {filterElement}
      </div>
    </div>
  );
}

export function dateCreatedFormatter(cellContent) {
  return (
    <span>{cellContent ? format(utcToZonedTime(new Date(cellContent), getIANATimezoneStringForAbbreviation(REPORTING_TIMEZONE)), 'MM/dd/yyyy hh:mm aa') : "--"}</span>
  )
}

export const getIANATimezoneStringForAbbreviation = (timezoneAbbreviation) => {
  switch (timezoneAbbreviation) {
    case 'America/Los_Angeles':
      return 'America/Los_Angeles';
    case 'America/New_York':
      return 'America/New_York';
    case 'America/Chicago':
      return 'America/Chicago';
    case 'America/Denver':
      return 'America/Denver';
    case 'America/Juneau':
      return 'America/Juneau';
    case 'Pacific/Honolulu':
      return 'Pacific/Honolulu';
    case 'Etc/UTC':
      return 'Etc/UTC';
  }
};

export const timeFormatter = (seconds) => {
  seconds = parseInt(seconds)
  if (!seconds) {
    seconds = 0
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export const getPrimaryTextClass = () => {
  return 'text-ccblue'
}

export const getPrimaryButtonClass = () => {
  return 'ccblue'
}

export const CcPercentStringFormatter = (value) => `${new Intl.NumberFormat().format(value)}%`;

export const CcCommaStringFormatter = (value) => `${new Intl.NumberFormat().format(value)}`;

export function toFixed(value, decimal) {
  if (!value) {
    return 0
  }
  if (typeof decimal !== 'number') {
    throw new Error('decimal argument must be a number');
  }
  return value.toFixed(decimal)
}

export const capitalizeFirstLetter = (str) => {
  if (str && typeof str === 'string' && str.length > 0) {
    return str.trim().charAt(0).toUpperCase() + str.slice(1);
  }
  return '';
};

export const xAxisOffsetForHourTimeFrame = (props) => {
  if (props?.timeframe_type === TIMEFRAME_TYPES.hour) {
    return {
      min: 0,
      tickAmount: props?.date_ranges?.length,
    }
  } else {
    return {}
  }
}

export const getMobileDataPercentage = (data) => {
  if (!data?.click_analytic_searches) {
    return data?.click_analytic_searches;
  }
  const per = (data?.click_analytic_mobile_traffic / data?.click_analytic_searches) * 100.0;
  return per.toFixed(2);
};

export const getGraphDateOverTimeRange = (metric, date_ranges, current_data) => {
  const filtered_array = date_ranges?.filter((e) => (current_data ? !['from_comparison', 'to_comparison', 'prev'].includes(e.meta) : ['prev'].includes(e.meta)));
  switch (metric) {
    // case 'clicks': return filtered_array.map((e) => e[metric])
    default:
      return filtered_array.map((e) => e[metric])
  }
};

export const getLocaleString = (data) => {
  return data?.toLocaleString() || "0";
};

export const mergeChartDataEntries = (data) => {
  const mergedData = [];
  const metaDates = new Set([...data || []].map(item => item.meta));

  metaDates.forEach(date => {
    const mergedItem = { meta: date };
    (data || []).forEach(item => {
      if (item.meta === date) {
        Object.keys(item).forEach(key => {
          if (key !== 'meta') {
            mergedItem[key] = item[key];
          }
        });
      }
    });
    mergedData.push(mergedItem);
  });

  return mergedData;
};


export const parseTimeframeStart = (payload, ianaTz = REPORTING_TIMEZONE) => {
  return payload.map(period_data => {

    let meta = '';
    const zonedStart = utcToZonedTime(period_data.timeframe_start, ianaTz);
    switch (period_data.timeframe_type) {
      case TIMEFRAME_TYPES.hour:
        meta = `${format(zonedStart, 'hh:mm a')} - ${format(addMinutes(zonedStart, 59), 'hh:mm a')}`
        break;
      case TIMEFRAME_TYPES.day:
        meta = `${format(zonedStart, 'LLL dd')}`;
        break;
      case TIMEFRAME_TYPES.week:
        meta = `${format(zonedStart, 'LLL dd')} - ${format(addWeeks(zonedStart, 1), 'LLL dd')}`
        break;
      case TIMEFRAME_TYPES.month:
        meta = `${format(zonedStart, 'MMM yy')}`;
        break;
      case TIMEFRAME_TYPES.year:
        meta = `${format(zonedStart, 'yyyy')}`;
        break;
    }
    return {
      ...period_data,
      meta
    }
  })
}

export const filterDataByPropertyValue = (data, key, value) => {
  return data.filter(item => item[key] === value);
}

export const percIncrease = (a, b) => {
  a = _.toInteger(a * 100);
  b = _.toInteger(b * 100);

  let percent;
  if (a !== 0) {
    percent = (b - a) / a * 100;
  } else {
    percent = b * 100;
  }
  return percent.toFixed(2);
};

export const SfPercentStringFormatter = (value) => `${new Intl.NumberFormat().format(value)}%`;

export const SfDollarStringFormatter = (value) => {
  if (value === undefined || value === null) {
    value = 0;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(value);
};

export const SfCommaStringFormatter = (value) => `${new Intl.NumberFormat().format(value)}`;

export const findbyGroupByKeys = (arr, key, value) => {
  return arr.find(item => item[key] === value);
};
export const checkUrlFiltersPresent = (urlQueryParams) => {
  return urlQueryParams?.indexOf('?') > 0
}

export const getParsedFilters = (urlQueryParams) => {
  const prevQueryParams = urlQueryParams.split('filters=')[1];
  const decodedURI = decodeURIComponent(prevQueryParams ? prevQueryParams : DEFAULT_FILTERD_URL_PARAMS)
  return safeJSONParse(decodeURIComponent(decodedURI), {});
}

export const DEFAULT_FILTERD_URL_PARAMS = encodeURIComponent('{"page_number":1,"filters":null}');

export const safeJSONParse = (str, fallback) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return fallback;
  }
}
