import React from 'react';
import { Input } from 'reactstrap';

const TextInputWithLabel = ({
    index,
    filter,
    handle_filter_values
}) => {
    return (
        <Input
            type={filter?.component_type}
            name={filter?.name}
            value={filter?.value || ""}
            onChange={(e) => handle_filter_values(index, e.target.value)}
        />
    )
}

export default TextInputWithLabel
