module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  AppConfiguration: {
    BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
    CONSENT_CAPTURE_SCRIPT_URL: process.env.REACT_APP_CONSENT_CAPTURE_SOURCE_URL,
    MOCK_URL: process.env.REACT_APP_MOCK_URL,
    REACT_APP_PRODUCT_NAME: process.env.REACT_APP_PRODUCT_NAME,
    WEBSITE: process.env.REACT_APP_PRODUCT_NAME
  }
}
