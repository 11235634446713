import _ from 'lodash';
import * as action_type from './actionTypes';

const initialState = {
  campaignEvents: [],
  loading: false,
  error: null,
  last_page_fetched: 0,
  total_number_of_pages: 0
};

const CertificateVault = (state = initialState, action) => {
  switch (action.type) {
    case action_type.GET_CAMPAIGN_EVENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case action_type.GET_CAMPAIGN_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        last_page_fetched: parseInt(action.payload.additional_data?.page_number),
        total_number_of_pages: parseInt(action.payload.additional_data?.total_number_of_pages),
        campaignEvents: _.uniqBy([...state.campaignEvents, ...action?.payload?.data], 'certificate_id'),
      };
    case action_type.GET_CAMPAIGN_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case action_type.GET_FILTERED_CAMPAIGN_EVENTS:
      const page_number = parseInt(action.payload.page_number || 1)
      return {
        ...state,
        loading: true,
        error: null,
        campaignEvents: (page_number === 1) ? [] : state.campaignEvents,
      };
    default:
      return state;
  }
};

export default CertificateVault;