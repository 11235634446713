import {
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  RESEND_USER_INVITE,
  RESEND_USER_INVITE_SUCCESS,
  RESEND_USER_INVITE_ERROR,
  RESET_USER_INVITE_SENT_TO
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: { users },
})

export const getUsersError = error => ({
  type: GET_USERS_ERROR,
  payload: error,
})

export const addNewUser = user => {
  return {
    type: ADD_NEW_USER,
    payload: user,
  }
}

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserError = error => ({
  type: ADD_USER_ERROR,
  payload: error,
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: { user },
})

export const updateUserError = error => ({
  type: UPDATE_USER_ERROR,
  payload: error,
})

export const deleteUser = id => ({
  type: DELETE_USER,
  payload: id,
})

export const deleteUserSuccess = user => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: { user },
  }
}

export const deleteUserError = error => ({
  type: DELETE_USER_ERROR,
  payload: error,
})

export const resendUserInvite = (user) => ({
  type: RESEND_USER_INVITE,
  payload: user
})

export const resendUserInviteSuccess = (payload) => ({
  type: RESEND_USER_INVITE_SUCCESS,
  payload: payload
})

export const resendUserInviteError = (error) => ({
  type: RESEND_USER_INVITE_ERROR,
  payload: error
})

export const resetUserInviteSentTo = () => ({
  type: RESET_USER_INVITE_SENT_TO
})