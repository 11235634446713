// sagas.js
import { call, put, takeLatest } from 'redux-saga/effects';
import { createCampaign, getCampaignById, getCampaigns, getCampaignsStats, updateCampaign } from '../../utils/Api';
import * as campaignActions from './actionTypes';
import {
  fetchCampaignsSuccess,
  fetchCampaignsFailure,
  saveCampaignSuccess,
  saveCampaignFailure,
  fetchCampaignStatSuccess,
  getCampaignByIdFailure,
  getCampaignByIdSuccess,
  updateCampaignSuccess,
  updateCampaignFailure,
  campaignStatsComparedDataSuccess,
  campaignStatsComparedDataFailure
} from './actions';

export function* saveCampaign(action) {
  try {
    yield call(createCampaign, action.payload);
    yield put(saveCampaignSuccess());
  } catch (error) {
    yield put(saveCampaignFailure(error.message));
  }
}

export function* saveCampaignSaga() {
  yield takeLatest(campaignActions.SAVE_CAMPAIGN_REQUEST, saveCampaign);
}

export function* getAllCamapigns() {
  try {
    const data = yield call(getCampaigns);
    yield put(fetchCampaignsSuccess(data));
  } catch (error) {
    yield put(fetchCampaignsFailure(error));
  }
}

export function* getAllCamapignsStats(action) {
  try {
    const statsData = yield call(getCampaignsStats, action.payload);
    yield put(fetchCampaignStatSuccess(statsData));
  } catch (error) {
    yield put(fetchCampaignsFailure(error));
  }
}

export function* getCampaignByIdSaga(action) {
  try {
    const campaignId = action.payload;
    const campaign = yield call(getCampaignById, campaignId);
    yield put(getCampaignByIdSuccess(campaign));
  } catch (error) {
    yield put(getCampaignByIdFailure(error));
  }
}

export function* updateCampaignSaga(action) {
  try {
    const campaign = yield call(updateCampaign, action.payload);
    yield put(updateCampaignSuccess(campaign));
  } catch (error) {
    yield put(updateCampaignFailure(error.message));
  }
}

export function* getCampaignStatsComparedData(action) {
  try {
    const statsData = yield call(getCampaignsStats, action.payload);
    yield put(campaignStatsComparedDataSuccess(statsData));
  } catch (error) {
    yield put(campaignStatsComparedDataFailure(error));
  }
}

export default function* campaignsSaga() {
  yield takeLatest(campaignActions.FETCH_CAMPAIGNS_REQUEST, getAllCamapigns);
  yield takeLatest(campaignActions.FETCH_CAMPAIGN_STATS_REQUEST, getAllCamapignsStats);
  yield takeLatest(campaignActions.GET_CAMPAIGN_BY_ID_REQUEST, getCampaignByIdSaga);
  yield takeLatest(campaignActions.UPDATE_CAMPAIGN_REQUEST, updateCampaignSaga);
  yield takeLatest(campaignActions.CAMPAIGN_STATS_COMPARED_DATA_REQUEST, getCampaignStatsComparedData);
}
