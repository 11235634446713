import { useState, useEffect } from 'react';

const useClearableTimeout = () => {
    const [customTimeoutState, setCustomTimeoutState] = useState(null);

    useEffect(() => {
        return () => {
            if(customTimeoutState) {
                clearTimeout(customTimeoutState);
            }
        }
    }, [])

    const customSetTimeout = (cb, timeInMs) => {
        if (customTimeoutState) {
            clearTimeout(customTimeoutState);
        }

        setCustomTimeoutState(setTimeout(cb, timeInMs));
    }

    const customClearTimeout = () => {
        if(customTimeoutState) {
            clearTimeout(customTimeoutState);
        }
    }

    return [customSetTimeout, customClearTimeout];
}

export default useClearableTimeout;