import React from 'react';
import { Input } from 'reactstrap';

const SearchFilters = ({
    name,
    value,
    placeholder,
    handle_on_change_value
}) => {
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }
    return (
        <div className="search-box me-2 d-inline-block">
            <div className="position-relative">
                <Input
                    className="rounded sf-box-w"
                    value={value || ""}
                    name={name}
                    placeholder={placeholder}
                    onChange={(e) => handle_on_change_value(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <i className="bx bx-search-alt search-icon" />
            </div>
        </div>
    )
}

export default SearchFilters
