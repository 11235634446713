import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col } from "reactstrap"

const Breadcrumb = props => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="text-capitalize text-black mb-0">
            {props.backOption &&
              <Link to={props.link}>
                <i className={`bx bx-arrow-back font-size-16 me-2 bx-sm`} />
              </Link>
            }
            {props.breadcrumbItem}
          </h4>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
