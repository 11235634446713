// rootSaga.js
import { all, fork } from "redux-saga/effects";
import { loginSaga } from "./auth/login/saga";
import {forgetPasswordSaga, resetPasswordSaga} from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import { registerSaga } from './auth/register/saga';
import campaignsSaga, { saveCampaignSaga } from "./campaigns/saga";
import userSaga from './user/saga';
import usersSaga from "./accounts/saga";
import ConsentSaga from "./consentCertificates/saga";
import ReplaySaga from "./replay/saga";
import DashboardSaga from "./dashboard/saga";
import CertificateVaultSaga from "./certificate-vault/saga";


export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(forgetPasswordSaga),
    fork(resetPasswordSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(campaignsSaga),
    fork(registerSaga),
    fork(saveCampaignSaga),
    fork(userSaga),
    fork(usersSaga),
    fork(ConsentSaga),
    fork(ReplaySaga),
    fork(DashboardSaga),
    fork(CertificateVaultSaga),
  ]);
}
