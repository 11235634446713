import React from 'react';
import './ErrorPage.css';  // Import the CSS file

const ErrorPage = ({ errorMsg }) => {
  return (
    <div className="error-container">
      <div className="error-content">
        <p>{errorMsg}</p>
      </div>
    </div>
  );
};

export default ErrorPage;
