import React, { useState } from "react";
import { Button, Card, CardBody, Col, Input, Label, Row, Form, Alert } from 'reactstrap';
import Select from 'react-select';
import { AppConfiguration } from "../../config";
import { useSelector } from "react-redux";
import LabelWithTooltip from "components/common/LabelWithTooltip";
import i18n_keys from "i18n_keys";
import { t } from "i18next"


const CampaignForm = ({
  campaignName,
  selectedRetainCertificate,
  handleNameCampaignChange,
  handleRetainCertificateChange,
  handleSubmit,
  retainCertificateOptions,
  consentConfirmationToken
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const { updating } = useSelector(state => ({
    updating: state.campaignReducer.updating,
  }));

  const handleCopyClick = () => {
    navigator.clipboard.writeText(`(function () 
      {
        var s = document.createElement('script');
        s.id = 'xxConsentConfirmationxx';
        if (document.getElementById('xxConsentConfirmationxx')) {
            return;
        }
        s.type = 'text/javascript';
        s.async = true;
        s.src = "${AppConfiguration?.CONSENT_CAPTURE_URL}";
        var ss = document.getElementsByTagName('script')[0];
        ss.parentNode.insertBefore(s, ss);
        window.xxConsentConfirmationTokenxx = '${consentConfirmationToken}';
      })()`);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const renderConsentConfirmationScript = () => {
    if (!consentConfirmationToken) return null;

    return (
      <>
        <Row>
          <p style={{ textAlign: 'left', marginTop: '20px', marginBottom: '5px' }}>
            <sup>**</sup>Copy the following code and paste it into the head section of each page you want to capture.
          </p>
          <div style={{ background: '#f5f5f5', marginBottom: "20px", width: '90%', position: 'relative', marginLeft: '12px' }}>
            <pre>
              <code>{`
                <script>
                  (function () 
                  {
                    var s = document.createElement('script');
                    s.id = 'xxConsentConfirmationxx';
                    if (document.getElementById('xxConsentConfirmationxx')) {
                        return;
                    }
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = "${AppConfiguration?.CONSENT_CAPTURE_SCRIPT_URL}";
                    var ss = document.getElementsByTagName('script')[0];
                    ss.parentNode.insertBefore(s, ss);
                    window.xxConsentConfirmationTokenxx = '${consentConfirmationToken}';
                  })()
                </script>
              `}</code>
            </pre>
            <Button color="info" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={handleCopyClick}>
              {isCopied ? 'Copied!' : 'Copy'}
            </Button>
          </div>
        </Row>
      </>
    );
  };

  return (
    <div>
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col className="col-2">
                <LabelWithTooltip
                  name='Campaign'
                  tooltip_message={t(i18n_keys.CREATE_CAMPAIGN_TOOLTIP_MESSAGE)}
                  target='campaign'
                  is_active={true}
                />
                <Input
                  type="text"
                  name="name"
                  value={campaignName}
                  onChange={handleNameCampaignChange}
                  placeholder="Enter campaign name"
                  required
                />
              </Col>
              <Col className="col-2">
                <LabelWithTooltip
                  name='Retain Certificates for'
                  tooltip_message={t(i18n_keys.RETAIN_CERTIFICATE_FOR_TOOLTIP_MESSAGE)}
                  target='retain_certificate_for'
                  is_active={true}
                />
                <Select
                  value={selectedRetainCertificate}
                  onChange={handleRetainCertificateChange}
                  options={retainCertificateOptions}
                  className="select2-selection__menu"
                  isInvalid={!selectedRetainCertificate}
                  required
                />
              </Col>
            </Row>
            {renderConsentConfirmationScript()}
            <div style={{ marginTop: '20px' }}>
              {updating ? (
                <Button color="primary" type="submit">Saving</Button>
              ) :
                <Button color="primary" type="submit">Save</Button>
              }
            </div>
          </Form>
        </CardBody>
      </Card>
    </div >
  );
};

export default CampaignForm;
