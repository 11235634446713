export const us_states = [
  { name: "Alabama", isocode: "AL" },
  { name: "Alaska", isocode: "AK" },
  { name: "Arizona", isocode: "AZ" },
  { name: "Arkansas", isocode: "AR" },
  { name: "California", isocode: "CA" },
  { name: "Colorado", isocode: "CO" },
  { name: "Connecticut", isocode: "CT" },
  { name: "Delaware", isocode: "DE" },
  { name: "District of Columbia", isocode: "DC" },
  { name: "Florida", isocode: "FL" },
  { name: "Georgia", isocode: "GA" },
  { name: "Hawaii", isocode: "HI" },
  { name: "Idaho", isocode: "ID" },
  { name: "Illinois", isocode: "IL" },
  { name: "Indiana", isocode: "IN" },
  { name: "Iowa", isocode: "IA" },
  { name: "Kansa", isocode: "KS" },
  { name: "Kentucky", isocode: "KY" },
  { name: "Lousiana", isocode: "LA" },
  { name: "Maine", isocode: "ME" },
  { name: "Maryland", isocode: "MD" },
  { name: "Massachusetts", isocode: "MA" },
  { name: "Michigan", isocode: "MI" },
  { name: "Minnesota", isocode: "MN" },
  { name: "Mississippi", isocode: "MS" },
  { name: "Missouri", isocode: "MO" },
  { name: "Montana", isocode: "MT" },
  { name: "Nebraska", isocode: "NE" },
  { name: "Nevada", isocode: "NV" },
  { name: "New Hampshire", isocode: "NH" },
  { name: "New Jersey", isocode: "NJ" },
  { name: "New Mexico", isocode: "NM" },
  { name: "New York", isocode: "NY" },
  { name: "North Carolina", isocode: "NC" },
  { name: "North Dakota", isocode: "ND" },
  { name: "Ohio", isocode: "OH" },
  { name: "Oklahoma", isocode: "OK" },
  { name: "Oregon", isocode: "OR" },
  { name: "Pennsylvania", isocode: "PA" },
  { name: "Rhode Island", isocode: "RI" },
  { name: "South Carolina", isocode: "SC" },
  { name: "South Dakota", isocode: "SD" },
  { name: "Tennessee", isocode: "TN" },
  { name: "Texas", isocode: "TX" },
  { name: "Utah", isocode: "UT" },
  { name: "Vermont", isocode: "VT" },
  { name: "Virginia", isocode: "VA" },
  { name: "Washington", isocode: "WA" },
  { name: "West Virginia", isocode: "WV" },
  { name: "Wisconsin", isocode: "WI" },
  { name: "Wyoming", isocode: "WY" },
]

export const usStatesOptions = us_states.map(single_object => ({label: single_object?.name + " ("+single_object?.isocode+")" , value: single_object?.isocode}))

export const usStatesOptionsForSf_filters = us_states.map(single_object => single_object?.isocode);