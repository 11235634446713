import { REGISTER_USER, REGISTER_USER_SUCCESSFUL, REGISTER_USER_FAILED, SET_USER_TO_BE_CREATED } from './actionTypes';

export const registerRequest = (userData) => ({
  type: REGISTER_USER,
  payload: userData,
});

export const registerSuccess = (status) => ({
  type: REGISTER_USER_SUCCESSFUL,
  payload: status,
});

export const registerFailure = (error) => ({
  type: REGISTER_USER_FAILED,
  payload: error,
});

export const setUserToBeCreated = (userData) => ({
  type: SET_USER_TO_BE_CREATED,
  payload: userData,
});
