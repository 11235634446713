// actions.js
import * as campaignActions from './actionTypes';

export const currentUserRequest = (userData) => ({
  type: campaignActions.CURRENT_USER_REQUEST,
  payload: userData,
});

export const currentUserSuccess = (userData) => ({
  type: campaignActions.CURRENT_USER_SUCCESS,
  payload: userData
});

export const currentUserFailure = (error) => ({
  type: campaignActions.CURRENT_USER_FAILURE,
  payload: error,
});

