import React, { useEffect, useRef } from "react";
import { Container, Row, Col, UncontrolledTooltip } from 'reactstrap';
import ConsentCertificateDetails from "components/CertificateDetails/ConsentCertificateDetails";
import logo from '../../assets/images/logo-dark.png';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { t } from "i18next";
import i18n_keys from "i18n_keys";
import { MetaTags } from "react-meta-tags";
import Breadcrumbs from "../../components/common/Breadcrumb";
import ToastAlert, { ToastAlertIconType } from "components/common/ToastAlert/ToastAlert";
import ShareAndCopyUrl from "../../components/common/ShareAndCopyUrl";

function LoggedoutView() {
  const location = useLocation();
  const navigate = useNavigate();
  const certificateId = location.pathname.split('/').pop() || '';
  const isLoggedIn = !!localStorage.getItem('authToken');
  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/certificate/${certificateId}`);
    }
  }, [isLoggedIn, navigate]);
  const copyButtonRef = useRef(null);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.origin + `/certificateDetails/${certificateId}`);
    ToastAlert.fire({ icon: ToastAlertIconType.OK, title: "URL copied to clipboard." });
  };

  return (
    <>
      <div style={{ background: '#f6f6f6' }}>
        <header style={{ backgroundColor: '#FFFFFF', padding: '20px 0', marginBottom: '20px' }}>
          <MetaTags>
            <title>{t(i18n_keys.CERTIFICATE_VAULT) + t(i18n_keys.PAGE_TITLE_SUFFIX)}</title>
          </MetaTags>
          <Container fluid>
            <Row className="">
              <Col xs="auto" className="justify-content-start">
                <img src={logo} alt="Logo" style={{ height: '50px' }} />
              </Col>
            </Row>
          </Container>
        </header>
        <Container fluid>
        <Row style={{ paddingLeft: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'flex-start', paddingLeft: '18px' }}>
            <Breadcrumbs title="" breadcrumbItem="Certificate & Session Recording" />
            <ShareAndCopyUrl
              copyButtonRef={copyButtonRef}
              copyToClipboard={copyToClipboard}
              tooltipMessage={t(i18n_keys.COPY_CERTIFICATE_URLTOOLTIP_MESSAGE)}
            />
          </div>
          <ConsentCertificateDetails consent_certificate_id={certificateId} />
        </Row>
      </Container>
      </div>
    </>
  );
};

export default LoggedoutView;
