import React from 'react';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';

const ComparatorWithLabel = ({
    is_multi,
    index,
    filter,
    handle_filter_values,
    handle_filter_comparator_values
}) => {
    const styleMenuPortal = { menuPortal: base => ({ ...base, zIndex: 9999 }) }
    return (
        <Row>
            <Col md={12}>
                <Select
                    menuPortalTarget={document.body}
                    styles={styleMenuPortal}
                    id={`prospect-filters-${filter?.name}`}
                    name={filter?.name}
                    options={filter?.options || []}
                    value={filter?.value || null}
                    onChange={(value) => handle_filter_values(index, value)}
                    isMulti={is_multi}
                />
            </Col>
            <Col md={12} className="mt-2">
                <NumberFormat
                    className="form-control"
                    type="text"
                    allowNegative={false}
                    id={`prospect-filters-comparator-ref-${filter?.name}`}
                    autoComplete="off"
                    suffix={filter?.suffix}
                    onChange={(e) => handle_filter_comparator_values(index, e.target.value)}
                    value={filter?.comparator_value || null}
                    allowEmptyFormatting={true}
                />
            </Col>
        </Row>

    )
}

export default ComparatorWithLabel;
