// actions.js
import * as campaignActions from './actionTypes';

export const saveCampaignRequest = (campaignData) => ({
  type: campaignActions.SAVE_CAMPAIGN_REQUEST,
  payload: campaignData,
});

export const saveCampaignSuccess = () => ({
  type: campaignActions.SAVE_CAMPAIGN_SUCCESS,
});

export const saveCampaignFailure = (error) => ({
  type: campaignActions.SAVE_CAMPAIGN_FAILURE,
  payload: error,
});

export const fetchCampaignsRequest = () => ({
  type: campaignActions.FETCH_CAMPAIGNS_REQUEST,
});

export const fetchCampaignsSuccess = (campaigns) => ({
  type: campaignActions.FETCH_CAMPAIGNS_SUCCESS,
  payload: campaigns,
});

export const fetchCampaignsFailure = (error) => ({
  type: campaignActions.FETCH_CAMPAIGNS_FAILURE,
  payload: { error },
});

export const getCampaignRequest = () => ({
  type: campaignActions.FETCH_CAMPAIGNS_REQUEST,
  payload: {},
});

export const getCampaignStatRequest = (payload) => ({
  type: campaignActions.FETCH_CAMPAIGN_STATS_REQUEST,
  payload: payload,
});

export const fetchCampaignStatSuccess = (campaigns) => ({
  type: campaignActions.FETCH_CAMPAIGN_STATS_SUCCESS,
  payload: campaigns,
});

export const fetchCampaignStatFailure = (error) => ({
  type: campaignActions.FETCH_CAMPAIGN_STATS_FAILURE,
  payload: { error },
});

export const getCampaignByIdRequest = (campaignId) => ({
  type: campaignActions.GET_CAMPAIGN_BY_ID_REQUEST,
  payload: campaignId,
});

export const getCampaignByIdSuccess = (campaign) => ({
  type: campaignActions.GET_CAMPAIGN_BY_ID_SUCCESS,
  payload: campaign,
});

export const getCampaignByIdFailure = (error) => ({
  type: campaignActions.GET_CAMPAIGN_BY_ID_FAILURE,
  payload: error,
});

export const updateCampaignRequest = (campaignData) => ({
  type: campaignActions.UPDATE_CAMPAIGN_REQUEST,
  payload: campaignData,
});

export const updateCampaignSuccess = () => ({
  type: campaignActions.UPDATE_CAMPAIGN_SUCCESS,
});

export const updateCampaignFailure = (error) => ({
  type: campaignActions.UPDATE_CAMPAIGN_FAILURE,
  payload: error,
});

export const campaignStatsComparedDataRequest = (campaignData) => ({
  type: campaignActions.CAMPAIGN_STATS_COMPARED_DATA_REQUEST,
  payload: campaignData,
});

export const campaignStatsComparedDataSuccess = (campaignData) => ({
  type: campaignActions.CAMPAIGN_STATS_COMPARED_DATA_SUCCESS,
  payload: campaignData,
});

export const campaignStatsComparedDataFailure = (error) => ({
  type: campaignActions.CAMPAIGN_STATS_COMPARED_DATA_FAILURE,
  payload: error,
});
