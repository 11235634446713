import PieChart from "components/common/piechart";
import PropTypes from "prop-types"
import { Card, CardBody, Col, Row } from "reactstrap";
import { delimitterHTMLformatter, percentHTMLformatter } from "utils/Formatter";
import Skeleton from "react-loading-skeleton";
import { capitalizeFirstLetter } from "helpers/utlis";
import LabelWithTooltip from "components/common/LabelWithTooltip";
import './dashboard.scss';
import { DASHBOARD_METRIC_NAMES, NO_DATA_MESSAGE } from "helpers/constants";
import _ from "lodash";

const NO_DATA_MESSAGE_ELEMENT = <div className='mt-3 col-md-12 text-black'>{NO_DATA_MESSAGE}</div>;

const StateWidgets = ({ filterType, currentData, loading, isTooltip, tooltip, colors, pieChartData, isPieChart }) => {

    if (loading) {
        return (
            <Card className="sfgraph-widget-card" style={{ height: '180px' }}>
                <CardBody className="p-3 pb-2">
                    <div className="mt-2 mb-3">
                        <Skeleton />
                    </div>
                    <Skeleton count={3} />
                </CardBody>
            </Card>
        )
    }

    return (
        <Card className="sfgraph-widget-card" style={{ height: '180px' }}>
            <CardBody className="p-3">
                <Row>

                    <Col md={isPieChart && !_.isEmpty(pieChartData?.values) ? 8 : 12}>
                        <div className="d-flex data-points">
                            <div className="d-flex justify-content-between">
                                <div className="current--data">
                                    <h6 className="mb-0 font-compared-data fs-16 text-black">

                                        {isTooltip ? (
                                            <LabelWithTooltip
                                                name={filterType || ""}
                                                tooltip_message={tooltip.text}
                                                target={tooltip.id}
                                                is_active={true}
                                            />
                                        ) : { filterType }}
                                    </h6>

                                </div>
                            </div>
                        </div>

                        {(filterType === DASHBOARD_METRIC_NAMES.TOP_DOMAINS && _.isEmpty(currentData)) && NO_DATA_MESSAGE_ELEMENT}

                        {(isPieChart && _.isEmpty(pieChartData?.values)) && NO_DATA_MESSAGE_ELEMENT}

                        <div className="mt-2 pb-2">
                            <div  >
                                <div className="custom-scrollbar" style={{ width: "100%", height: '112px', overflowY: "auto", }}>
                                    <ul className="list-group me-2">
                                        {(currentData || []).map((item, index) => {
                                            return (
                                                <li key={index} class="d-flex justify-content-between align-items-center fs-13 mb-1">
                                                    <span>{isPieChart && <i className="bx bxs-circle me-1" style={{ color: colors[index] }}></i>}{item?.label ? (filterType === DASHBOARD_METRIC_NAMES.TOP_DOMAINS ? item?.label :capitalizeFirstLetter(item?.label)) : "--"}</span>
                                                    <span className="me-3 font-bold">{isPieChart ? percentHTMLformatter(item.value) : delimitterHTMLformatter(item.value)}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Col>

                    {isPieChart && pieChartData?.values && (<Col md={4}>
                        <div className="mt-4">
                            <PieChart
                                colors={colors}
                                series={pieChartData.values}
                                labels={pieChartData.labels}
                            />
                        </div>
                    </Col>)}
                </Row>
            </CardBody>
        </Card >
    )
}

StateWidgets.propTypes = {
    loading: PropTypes.bool,
}

export default StateWidgets
