import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
    Card, CardBody, Table,
} from 'reactstrap';
import 'react-loading-skeleton/dist/skeleton.css';
import PropTypes from 'prop-types';

function CustomSkeleton(props) {
    return (
        <Card>
            <CardBody>
                <Table>
                    <tbody>
                        {[...Array(props.rows)].map((elementInArray, index) => (
                            <tr key={index}>
                                {[...Array(props.cols)].map((elementInArray, index2) => (
                                    <td key={index + index2}><Skeleton /></td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
}

CustomSkeleton.propTypes = {
    rows: PropTypes.number,
    cols: PropTypes.number,
};

export default CustomSkeleton;
