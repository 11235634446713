import PropTypes from "prop-types"
import { META_TYPES, METRIC_TYPE_NAMES, VIEW_MODE_TYPE } from "helpers/constants";
import { Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import Skeleton from "react-loading-skeleton";
import { delimitterHTMLformatter, timeDurationFormatter } from "utils/Formatter";
import LabelWithTooltip from "components/common/LabelWithTooltip";

const GraphWidget = (props) => {
    const getSeries = (showDiff, series, filterTypeName) => {
        const new_series = (showDiff === true) ? (series) : ([series.find(e => e.name === META_TYPES.CURRENT)]);
        const mappedSeries = new_series.map(object => {
            return {
                ...object,
                name: (object?.name === META_TYPES.PREV ? `Previous ${filterTypeName}` : filterTypeName),
                data: object.data
            }
        });

        return mappedSeries;
    }

    if (props.loading) {
        return (
            <Card className="sfgraph-widget-card" style={{ height: '180px' }}>
                <CardBody className="p-3 pb-2">
                    <div className="mt-2 mb-3">
                        <Skeleton />
                    </div>
                    <Skeleton count={3} />
                </CardBody>
            </Card>
        )
    }
    return (
        <Card className="sfgraph-widget-card" style={props?.graphType === VIEW_MODE_TYPE.DETAILED ? {} : { height: '180px' }}>
            <CardBody className="p-3 pb-0">
                <div className="d-flex data-points">
                    {props.bx_icon ? props.bx_icon : ""}
                    <div className="d-flex " style={{ width: "100%" }}>
                        <div className="current--data">
                            <div className="">
                                <h6 className="mb-0 font-compared-data fs-16 text-black">
                                    {props.isTooltip ? (
                                        <LabelWithTooltip
                                    name={props?.filterType || ""}
                                    tooltip_message={props.tooltip.text}
                                    target={props.tooltip.id}
                                    is_active={true}
                                    />
                                    ) : (props?.filterType || "")} 
                                </h6>
                                <span className="font-size-current-data fw-600 fs-18">
                                    {props?.filterType === METRIC_TYPE_NAMES.average_duration ? timeDurationFormatter(props.currentData) : delimitterHTMLformatter(props.currentData)}
                                </span>
                            </div>
                        </div>
                        {(props.showDiff === true && props.dataDiff) ? (parseFloat(props?.dataDiff.replace("%", "")) >= 0 ?
                            <div className="pill--data align-self-center">
                                <span className='badge bg-success ms-2 py-1 px-2 rounded-pill'>
                                    {props.dataDiff}
                                </span>
                            </div>
                            :
                            <div className="pill--data align-self-center">
                                <span className='badge bg-danger ms-2 py-1 px-2 rounded-pill'>
                                    {props.dataDiff}
                                </span>
                            </div>
                        ) : null}
                        {props.showDiff && props.lastData ?
                            <div className={`me-2 ${(props?.graphType === VIEW_MODE_TYPE.DETAILED) ? 'ms-5' : ' compared--data'}`}>
                                <h6 className="mb-0 font-compared-data fs-14 text-muted">{props?.lastDateLabel}</h6>
                                <small className="font-size-current-data fw-600 fs-14 text-muted">{props.lastData}</small>
                            </div> : ""
                        }
                    </div>
                    {
                        (props?.graphType === VIEW_MODE_TYPE.DETAILED) && (<a onClick={() => props.closeWidget()}>
                            <i className="mdi mdi-close h4 text-sfgrey float-end" />
                        </a>)
                    }
                </div>
            </CardBody>
            <div className={(props?.graphType === VIEW_MODE_TYPE.DETAILED) ? "m-2 mt-0" : ""}>
                <ReactApexChart
                    options={props?.graphOption}
                    series={getSeries(props?.showDiff, props?.series, props?.filterType)}
                    type="line"
                    height={props?.height || 80}
                />
            </div>
        </Card>
    )
}

GraphWidget.propTypes = {
    lastDataLabel: PropTypes.string,
    dataDiff: PropTypes.string,
    currentData: PropTypes.object,
    lastData: PropTypes.object,
    series: PropTypes.array,
    showDiff: PropTypes.bool,
    loading: PropTypes.bool,
    filterType: PropTypes.string,
    graphType: PropTypes.string,
    closeWidget: PropTypes.func,
    tooltipInfo: PropTypes.string,
    tooltipid: PropTypes.string,
    bx_icon: PropTypes.object
}


export default GraphWidget;