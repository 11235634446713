import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

import { useSelector, useDispatch } from "react-redux"

import { Link, useNavigate } from "react-router-dom"

import * as Yup from "yup"
import { useFormik } from "formik"

import { userForgetPassword } from "../../store/actions"
import { AppConfiguration } from "config"
import logoDark from "assets/images/logo-dark.png";

const ForgetPasswordPage = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      dispatch(userForgetPassword(values, navigate))
    },
  })

  const { forgetError, forgetSuccessMsg, loading } = useSelector(state => ({
    forgetError: state.forgetPassword.forgetError,
    forgetSuccessMsg: state.forgetPassword.forgetSuccessMsg,
    loading: state.forgetPassword.loading,
  }))

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forgot Password | {AppConfiguration.WEBSITE}</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-white bg-soft">
                  <Row>
                    <Col xs={12}>
                      <div className="text-center p-4">
                        <img src={logoDark} height={70} />
                      </div>
                      <div className="px-4 ms-1">
                        <p className="text-muted">
                          <strong>
                            Enter your email below to reset your password
                          </strong>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label font-weight-semibold">
                          Email
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className={`btn btn-primary w-md`}
                            type="submit"
                          >
                            {loading ? "Please wait..." : "Reset"}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center text-white">
                <p>
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium text-blue">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Consent Confirmation
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default ForgetPasswordPage;