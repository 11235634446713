import Swal from 'sweetalert2';

const ToastAlert = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

export const ToastAlertIconType = {
  OK: 'success',
  ERROR: 'error',
  WARNING: 'warning'
}

export default ToastAlert;   