// sagas.js
import { call, put, takeLatest } from 'redux-saga/effects';
import { consentDetails } from '../../utils/Api';
import * as consentActions from './actionTypes';
import {
  consentCertificateDetailsSuccess,
  consentCertificateDetailsFailure
} from './actions';

export function* getConsentCertitifcateDetails(action) {
  try {
    const data = yield call(consentDetails, action.payload);
    yield put(consentCertificateDetailsSuccess(data));
  } catch (error) {
    yield put(consentCertificateDetailsFailure(error.message));
  }
}

export default function* ConsentSaga() {
  yield takeLatest(consentActions.CONSENT_CERTIFICATE_DETAILS_REQUEST, getConsentCertitifcateDetails);

}
