import CurrencyFormat from "react-currency-format"
import { UncontrolledTooltip } from "reactstrap";

export function delimitterHTMLformatter(value) {
  return (
    <CurrencyFormat
      displayType="text"
      decimalScale={2}
      value={(value === null) ? '0' : value}
      thousandSeparator
    />
  );
}

export const percentHTMLformatter = (value) => (
  <CurrencyFormat
    displayType="text"
    value={(value === null) ? '0' : value}
    suffix="%"
    decimalScale={2}
    fixedDecimalScale
    thousandSeparator
  />
);

export function defaultFormatter(cellContent) {
  return (
    <span>{cellContent ? cellContent : "--"}</span>
  )
}

export function defaultNumberFormatter(cellContent) {
  return (
    <span>{cellContent != null ? cellContent : 0}</span>
  )
}

export const timeDurationFormatter = (seconds) => {
  if (!seconds) {
    seconds = 0
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = parseInt(seconds % 60);

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export function onHoverPopUpTooltipFormatter(cellContent, row, colIndex, keyField) {
  return (
    <div className="text-truncate">
      <span id={`hover-tooltip-${keyField || ""}-${row?.id}-${colIndex}-formatter`}>{cellContent ? cellContent : "--"}</span>
      {
        cellContent && (
          <UncontrolledTooltip placement="left" target={`hover-tooltip-${keyField || ""}-${row?.id}-${colIndex}-formatter`}>
            {cellContent}
          </UncontrolledTooltip>
        )
      }
    </div>
  )
}