import React from 'react';
import DateRangePicker, { DATE_RANGE_OPTIONS, createOption } from '../../../common/DateRangePicker';
import { getIANATimezoneStringForAbbreviation } from 'helpers/utlis';

/**
 * DOBInputWithLabel Component
 * @param {string} propertyLabel - The label text for the input field (e.g., 'Date of Birth')
 * @param {string} propertyName - The name attribute for the input field (e.g., 'dob')
 * @param {any} propertyValue - The value of the input field (depends on the input type, e.g., date value)
 * @param {Function} handlePropertyChange - The function triggered on input change (e.g., onChange handler)
 * @param {any} propertyResourceValue - The value of the resource property (related to the input, if any)
 * @param {Function} handlePropertyResourceChange - The function to handle changes in the resource property
 * @param {string} currentAccountTimeZone - The timezone associated with the current account (e.g., 'UTC')
 * @returns {JSX.Element} - Returns a JSX element representing the component
 */
const DOBInputWithLabel = ({
    index,
    filter,
    handle_filter_values,
    timezone
}) => {

    const dateRangeOptions = Object.keys(DATE_RANGE_OPTIONS).map((opt) =>
        createOption(
            DATE_RANGE_OPTIONS[opt],
            new Date(),
            getIANATimezoneStringForAbbreviation(timezone)
        )
    ) || [];

    return (
        <DateRangePicker
            ianaTz={getIANATimezoneStringForAbbreviation(timezone)}
            value={filter?.value}
            options={dateRangeOptions}
            onChange={(value) => handle_filter_values(index, value)}
        />
    );
};

export default DOBInputWithLabel;
