// actions.js
import * as replayActions from './actionTypes';

export const replayRequest = (eventId, apiToken) => ({
  type: replayActions.REPLAY_REQUEST,
  payload: {eventId, apiToken},
});

export const replaySuccess = (data) => ({
  type: replayActions.REPLAY_SUCCESS,
  payload: data
});

export const replayFailure = (error) => ({
  type: replayActions.REPLAY_FAILURE,
  payload: error,
});
