import { endOfDay, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfQuarter, startOfWeek, startOfYear } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const BROWSER_IANA_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const calcZonedDate = (date, tz, fn, options = null) => {
    const inputZoned = utcToZonedTime(date, tz);
    const fnZoned = (options) ? fn(inputZoned, options) : fn(inputZoned);
    return zonedTimeToUtc(fnZoned, tz);
}

export const getZonedStartOfDay = (date, timezone) => {
    return calcZonedDate(date, timezone, startOfDay);
}

export const getZonedStartOfWeek = (date, timezone, options = { weekStartsOn: 1 }) => {
    return calcZonedDate(date, timezone, startOfWeek, options);
}

export const getZonedStartOfMonth = (date, timezone) => {
    return calcZonedDate(date, timezone, startOfMonth);
}

export const getZonedStartOfQuarter = (date, timezone) => {
    return calcZonedDate(date, timezone, startOfQuarter);
}

export const getZonedStartOfYear = (date, timezone) => {
    return calcZonedDate(date, timezone, startOfYear);
}

export const getZonedEndOfDay = (date, timezone) => {
    return calcZonedDate(date, timezone, endOfDay);
}

export const getZonedEndOfWeek = (date, timezone) => {
    return calcZonedDate(date, timezone, endOfWeek, { weekStartsOn: 1 });
}

export const getZonedEndOfMonth = (date, timezone) => {
    return calcZonedDate(date, timezone, endOfMonth);
}

export const getZonedEndOfQuarter = (date, timezone) => {
    return calcZonedDate(date, timezone, getZonedEndOfQuarter);
}

export const getZonedEndOfYear = (date, timezone) => {
    return calcZonedDate(date, timezone, endOfYear);
}

export const convertDateBetweenTimezones = (date, fromTimezone, toTimezone) => {
    return zonedTimeToUtc(utcToZonedTime(date, fromTimezone), toTimezone)
}
