import React from 'react';
import Select from 'react-select';

const SelectBoxWithLabel = ({
    is_multi,
    index,
    filter,
    handle_filter_values
}) => {
    const styleMenuPortal = { menuPortal: base => ({ ...base, zIndex: 9999 }) }
    return (
        <Select
            menuPortalTarget={document.body}
            styles={styleMenuPortal}
            id={`prospect-filters-${filter?.name}`}
            name={filter?.name}
            options={filter?.options || []}
            value={filter?.value || null}
            onChange={(value) => handle_filter_values(index, value)}
            isMulti={is_multi}
        />
    )
}

export default SelectBoxWithLabel;
