import React from 'react';
import UserStyle from '../../../assets/css/admin_user_filters_modal.css';
import { FILTER_COMPONENT_TYPES, YES_AND_NO } from 'helpers/constants';
import './ShowAppliedFilters.css';

const ShowAppliedFilters = ({ filtered_columns, onClickRemoveFilter }) => {
    return (
        <div className="d-flex flex-wrap align-items-center">
            {
                filtered_columns && filtered_columns.map((item, idx) => {
                    let filter_values = item?.value || '';
                    switch (item.component_type) {
                        case FILTER_COMPONENT_TYPES.SINGLE_SELECT:
                            filter_values = <span className='text-capitalize'>{(item?.value?.value === YES_AND_NO.NO || !item?.value?.value) ? YES_AND_NO.NO : (item?.value?.value === YES_AND_NO.YES || !!item?.value?.value) ? YES_AND_NO.YES : item?.value?.value}</span>
                            break;
                        case FILTER_COMPONENT_TYPES.MULTI_SELECT:
                            filter_values = ((item?.value || [])?.map(el => el.label) || []).join(', ')
                            break;
                        case FILTER_COMPONENT_TYPES.DATE_RANGE:
                            filter_values = item?.value?.label
                            break;
                        case FILTER_COMPONENT_TYPES.COMPARATOR:
                            filter_values = (`${item?.value?.label} : ${item?.comparator_value}`)
                            break;
                        default:
                            break;
                    }
                    return (
                        <div key={idx} className={`mx-2 mb-1 ${UserStyle.selected_filter}`}>
                            <div className="d-flex align-items-center filter-border">
                                <span className='text-black'>{item?.label} : <b>{filter_values}</b></span>
                                <a className={`ms-2  font-size-18`} onClick={() => onClickRemoveFilter(item?.name)} style={{ cursor: "pointer" }}>
                                    <span aria-hidden="true">&times;</span>
                                </a>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ShowAppliedFilters;

