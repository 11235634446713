import { createLabelWithDateRange, createOption, DATE_RANGE_OPTIONS } from '../components/common/DateRangePicker';
import { getZonedEndOfDay, getZonedStartOfDay, getZonedStartOfQuarter, getZonedStartOfYear } from './time_utils';
// import _ from 'lodash';

import {
  addDays, differenceInDays,
} from 'date-fns';

/**
* @brief Function to calculate the metric diff in percent
* @param data{Object} Data metrics containing the date range
* @param from_tag{string} From tag in the meta tag
* @param to_tag{string} To tag in the meta tag
*/

export const getPrevDataRangeFromSelectedDataRange = (selectedDateRange, timezone, new_compare_to_logic) => {
  let start_date = selectedDateRange.value.startDate;
  let end_date = selectedDateRange.value.endDate;
  const diff_days = differenceInDays(selectedDateRange.value.endDate, selectedDateRange.value.startDate) + 1;

  if (DATE_RANGE_OPTIONS.THIS_QUARTER == selectedDateRange.original_label && new_compare_to_logic) {
    [start_date, end_date] = getStartAndEndDateForPrevQuarter(start_date, end_date, timezone, diff_days)
  } else if (DATE_RANGE_OPTIONS.THIS_YEAR == selectedDateRange.original_label && new_compare_to_logic) {
    [start_date, end_date] = getStartAndEndDateForPrevYear(start_date, end_date, timezone, diff_days)
  } else {
    start_date = getZonedStartOfDay(addDays(start_date, -diff_days), timezone);
    end_date = getZonedEndOfDay(addDays(end_date, -diff_days), timezone);
  }

  let prev_date_range_options = [];
  switch (selectedDateRange.original_label) {
    case 'THIS_MONTH':
      prev_date_range_options.push(createOption(
        DATE_RANGE_OPTIONS.LAST_MONTH,
        new Date(),
        timezone,
      ));
      break;
    case 'TODAY':
      prev_date_range_options.push(createOption(
        DATE_RANGE_OPTIONS.YESTERDAY,
        new Date(),
        timezone,
      ));
      break;
    case 'THIS_WEEK':
      prev_date_range_options.push(createOption(
        DATE_RANGE_OPTIONS.LAST_WEEK,
        new Date(),
        timezone,
      ));
      break;

    case 'ALL_TIME': break;
    default:
      prev_date_range_options.push(
        {
          label: createLabelWithDateRange(
            {
              label: '',
              startDate: start_date,
              endDate: end_date,
            },
            timezone,
          ),
          original_label: 'CUSTOM_COMPARE',
          value: {
            startDate: start_date,
            endDate: end_date,
          },
        },
      );
  }
  prev_date_range_options = prev_date_range_options.concat(Object.keys(DATE_RANGE_OPTIONS).map((e) => createOption(
    e,
    new Date(),
    timezone,
  )));
  return prev_date_range_options;
};

// change previous date range period to corresponding selected date range period
export const changePrevTimeRangeBySelectedTimeRange = (prevTimeRange, selectedTimeRange) => {
  let selectedTimeRangeLabel = selectedTimeRange.original_label
  let prevSelectedTimeRangeLabel = prevTimeRange.original_label
  if (selectedTimeRangeLabel == DATE_RANGE_OPTIONS.ALL_TIME) {
    return
  }
  const duration = selectedTimeRange.value.endDate.getTime() - selectedTimeRange.value.startDate.getTime()
  const prevEndDate = new Date(prevTimeRange?.value?.startDate?.getTime() + duration);

  switch (selectedTimeRangeLabel) {
    case DATE_RANGE_OPTIONS.TODAY:
    case DATE_RANGE_OPTIONS.THIS_WEEK:
    case DATE_RANGE_OPTIONS.THIS_MONTH:
    case DATE_RANGE_OPTIONS.THIS_YEAR:
    case DATE_RANGE_OPTIONS.THIS_QUARTER:
      if ([DATE_RANGE_OPTIONS.YESTERDAY, DATE_RANGE_OPTIONS.LAST_WEEK,
      DATE_RANGE_OPTIONS.LAST_MONTH].includes(prevSelectedTimeRangeLabel)) {
        return prevEndDate
      } else {
        return prevTimeRange.value.endDate
      }
    case DATE_RANGE_OPTIONS.ALL_TIME: break;
    default:
      return prevTimeRange.value.endDate
  }
}

export const getStartAndEndDateForPrevQuarter = (start_date, end_date, timezone, diff_days) => {
  let month = start_date.getMonth();
  let temp_start_date = new Date(start_date)
  temp_start_date.setMonth(month - 3)
  if (temp_start_date.getDate() < start_date.getDate()) {
    temp_start_date.setDate(0)
  }

  start_date = getZonedStartOfQuarter(temp_start_date, timezone)
  end_date = getZonedEndOfDay(addDays(start_date, diff_days - 1), timezone)
  return [start_date, end_date]
}

export const getStartAndEndDateForPrevYear = (start_date, end_date, timezone, diff_days) => {
  let year = start_date.getFullYear()
  let temp_start_date = new Date(start_date)
  temp_start_date.setFullYear(year - 1, 0, 1)
  start_date = getZonedStartOfYear(temp_start_date, timezone)
  end_date = getZonedEndOfDay(addDays(start_date, diff_days - 1), timezone)
  return [start_date, end_date]
}
