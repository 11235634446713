import {
  POST_DASHBOARD_MAIN_STATS,
  POST_DASHBOARD_MAIN_STATS_SUCCESS,
  POST_DASHBOARD_MAIN_STATS_ERROR,
  POST_DASHBOARD_MAIN_DETAIL_STATS,
  POST_DASHBOARD_MAIN_DETAIL_STATS_SUCCESS,
  POST_DASHBOARD_MAIN_DETAIL_STATS_ERROR,
  POST_DASHBOARD_DEVICE_TYPE_STATS,
  POST_DASHBOARD_DEVICE_TYPE_STATS_SUCCESS,
  POST_DASHBOARD_DEVICE_TYPE_STATS_ERROR,
  POST_DASHBOARD_BROWSER_TYPE_STATS,
  POST_DASHBOARD_BROWSER_TYPE_STATS_SUCCESS,
  POST_DASHBOARD_BROWSER_TYPE_STATS_ERROR,
  POST_DASHBOARD_DOMAIN_STATS,
  POST_DASHBOARD_DOMAIN_STATS_SUCCESS,
  POST_DASHBOARD_DOMAIN_STATS_ERROR,
  POST_DASHBOARD_STATE_STATS,
  POST_DASHBOARD_STATE_STATS_SUCCESS,
  POST_DASHBOARD_STATE_STATS_ERROR,
  POST_DASHBOARD_COMPARE_TO_MAIN_STATS_ERROR,
  POST_DASHBOARD_COMPARE_TO_MAIN_STATS_SUCCESS,
  POST_DASHBOARD_COMPARE_TO_MAIN_STATS
} from './actionTypes';

export const fetchDashboardMainStats = (req) => ({
  type: POST_DASHBOARD_MAIN_STATS,
  payload: req,
});

export const fetchDashboardMainStatsSuccess = (res) => ({
  type: POST_DASHBOARD_MAIN_STATS_SUCCESS,
  payload: res,
});

export const fetchDashboardMainStatsError = (err) => ({
  type: POST_DASHBOARD_MAIN_STATS_ERROR,
  payload: err,
});

export const fetchDashboardCompareToMainStats = (req) => ({
  type: POST_DASHBOARD_COMPARE_TO_MAIN_STATS,
  payload: req,
});

export const fetchDashboardCompareToMainStatsSuccess = (res) => ({
  type: POST_DASHBOARD_COMPARE_TO_MAIN_STATS_SUCCESS,
  payload: res,
});

export const fetchDashboardCompareToMainStatsError = (err) => ({
  type: POST_DASHBOARD_COMPARE_TO_MAIN_STATS_ERROR,
  payload: err,
});

export const fetchDashboardMainDetailStats = (req) => ({
  type: POST_DASHBOARD_MAIN_DETAIL_STATS,
  payload: req,
});

export const fetchDashboardMainDetailStatsSuccess = (res) => ({
  type: POST_DASHBOARD_MAIN_DETAIL_STATS_SUCCESS,
  payload: res,
});

export const fetchDashboardMainDetailStatsError = (err) => ({
  type: POST_DASHBOARD_MAIN_DETAIL_STATS_ERROR,
  payload: err,
});

export const fetchDashboardDeviceTypeStats = (req) => ({
  type: POST_DASHBOARD_DEVICE_TYPE_STATS,
  payload: req,
});

export const fetchDashboardDeviceTypeStatsSuccess = (res) => ({
  type: POST_DASHBOARD_DEVICE_TYPE_STATS_SUCCESS,
  payload: res,
});

export const fetchDashboardDeviceTypeStatsError = (err) => ({
  type: POST_DASHBOARD_DEVICE_TYPE_STATS_ERROR,
  payload: err,
});

export const fetchDashboardBrowserTypeStats = (req) => ({
  type: POST_DASHBOARD_BROWSER_TYPE_STATS,
  payload: req,
});

export const fetchDashboardBrowserTypeStatsSuccess = (res) => ({
  type: POST_DASHBOARD_BROWSER_TYPE_STATS_SUCCESS,
  payload: res,
});

export const fetchDashboardBrowserTypeStatsError = (err) => ({
  type: POST_DASHBOARD_BROWSER_TYPE_STATS_ERROR,
  payload: err,
});

export const fetchDashboardDomainStats = (req) => ({
  type: POST_DASHBOARD_DOMAIN_STATS,
  payload: req,
});

export const fetchDashboardDomainStatsSuccess = (res) => ({
  type: POST_DASHBOARD_DOMAIN_STATS_SUCCESS,
  payload: res,
});

export const fetchDashboardDomainStatsError = (err) => ({
  type: POST_DASHBOARD_DOMAIN_STATS_ERROR,
  payload: err,
});

export const fetchDashboardStateStats = (req) => ({
  type: POST_DASHBOARD_STATE_STATS,
  payload: req,
});

export const fetchDashboardStateStatsSuccess = (res) => ({
  type: POST_DASHBOARD_STATE_STATS_SUCCESS,
  payload: res,
});

export const fetchDashboardStateStatsError = (err) => ({
  type: POST_DASHBOARD_STATE_STATS_ERROR,
  payload: err,
});