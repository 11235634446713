import React from "react";
import { Navigate } from "react-router-dom";

import UserProfile from "../pages/Authentication/user-profile";

import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPassword from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";

import Dashboard from "../pages/Dashboard/index";
import Campaigns from "../pages/Campaigns/index";
import NewCampaign from "../pages/Campaigns/newCampaign";
import EditCampaign from "../pages/Campaigns/editCampaign";
import LoggedinView from "pages/ConsentCertificateDetails/LoggedinView";
import LoggedoutView from "pages/ConsentCertificateDetails/LoggedoutView";
import Users from "pages/Organization/Users";
import ReplaySession from "../pages/ReplayPage/ReplayPage"
import CertificateVault from "pages/CertificateVault/CertificateVault";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/campaigns", component: <Campaigns /> },
  { path: "/certificate-vault", component: <CertificateVault /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/campaigns/create", component: <NewCampaign /> },
  { path: "/campaigns/:id", component: <EditCampaign /> },
  { path: "/users", component: <Users/> },
  { path: "/certificate/:certificateId", component: <LoggedinView /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPassword /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/register", component: <Register /> },
  { path: "/replay_session/:certificateId", component: <ReplaySession /> },
  { path: "/certificateDetails/:certificateId", component: <LoggedoutView /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to='/login' />,
  },
];

export { authProtectedRoutes, publicRoutes };
