import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchData } from '../../utils/Api';
import * as replayActions from './actionTypes';
import {
  replaySuccess,
  replayFailure
} from './actions';

export function* getReplayData(action) {
  try {
    const response = yield call(fetchData, action.payload);
    let data;
    if (response.raw_events) {
      data = response.events;
    } else {
      data = yield call(fetchJSONFromS3, response.events);
    }
    yield put(replaySuccess(data));
  } catch (error) {
    yield put(replayFailure(error.message));
  }
}

export function* fetchJSONFromS3(s3url) {
  try {
    const response = yield fetch(s3url);
    if (!response.ok) {
      throw new Error('Failed to fetch data from S3');
    }
    const jsonData = yield response.json();
    return jsonData;
  } catch (error) {
    console.error('Error fetching JSON from S3:', error);
    throw error;
  }
}

export default function* ReplaySaga() {
  yield takeLatest(replayActions.REPLAY_REQUEST, getReplayData);
}
