import React, { useState } from "react";
import { MetaTags } from 'react-meta-tags';
import { Card, Container, Row, Col } from 'reactstrap';
import { useDispatch } from "react-redux"; // Import useDispatch
import Breadcrumbs from "../../components/common/Breadcrumb";
import { RETAIN_CERTIFICATE_TIME_OPTIONS } from "../../helpers/constants";
import { saveCampaignRequest } from "../../store/campaigns/actions";
import CampaignForm from "../../components/CampaignComponent/CampaignForm";
import { useNavigate } from 'react-router-dom';

import '../../assets/scss/theme.scss';
import '../../assets/css/datatables.scss'
import i18n_keys from "i18n_keys";
import { t } from "i18next";

const retain_certificate_time_options = RETAIN_CERTIFICATE_TIME_OPTIONS

function NewCampaigns() {
  const [selectedRetainCertificate, setSelectedRetainCertificate] = useState(null);
  const [campaignName, setCampaignName] = useState('');
  const dispatch = useDispatch(); // Get dispatch function
  const navigate = useNavigate();

  const handleRetainCertificateChange = selectedOption => {
    setSelectedRetainCertificate(selectedOption);
  };

  const handleNameCampaignChange = e => {
    setCampaignName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      name: campaignName,
      retain_certificate_for: selectedRetainCertificate.value
    };
    dispatch(saveCampaignRequest(requestData));
    navigate('/campaigns');
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>New Campaign {t(i18n_keys.PAGE_TITLE_SUFFIX)}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="New Campaign" link={"/campaigns"} backOption={true} />
          <Row>
            <Col className="col-12">
                <CampaignForm
                  campaignName={campaignName}
                  selectedRetainCertificate={selectedRetainCertificate}
                  handleNameCampaignChange={handleNameCampaignChange}
                  handleRetainCertificateChange={handleRetainCertificateChange}
                  handleSubmit={handleSubmit}
                  retainCertificateOptions={retain_certificate_time_options}
                />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default NewCampaigns;
