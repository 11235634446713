import PropTypes from 'prop-types';
import React from 'react';
import { Label, UncontrolledTooltip } from 'reactstrap';
/**
 * 
 * @param {string} name | name of the column
 * @param {string} tooltip_message | message to be displayed in tooltip
 * @param {string} target | id of the element
 * @param {string} htmlFor | for label tag
 * @returns html
 */
const LabelWithTooltip = ({ name, tooltip_message, target, htmlFor, is_active = true }) => {
    return (<>
        <Label for={htmlFor} className="font-weight-semibold d-flex align-items-center">
            {name}
            {is_active && <i id={target} className='ms-1 bx bx-info-circle text-black bx-xs'></i>}
        </Label>
        {is_active && <UncontrolledTooltip placement="top" target={target}>{tooltip_message}</UncontrolledTooltip>}
    </>);
}

LabelWithTooltip.propTypes = {
    name: PropTypes.string,
    tooltip_message: PropTypes.string,
    target: PropTypes.string,
    htmlFor: PropTypes.string,
};

export default LabelWithTooltip;