import { COMPARISON_FIELD_OPTIONS, FILTER_COMPONENT_TYPES, STATUS_TYPES, YES_AND_NO} from '../constants'; 

export const PROSPECT_FILTERED_COLUMNS_NAMES = {
    CAMPAIGN_NAME: "campaign_name",
    CERTIFICATE_ID: "certificate_id",
    DURATION: "duration",
    PAGE_URL: "page_url",
    IP_ADDRESS: "ip_address",
    LOCATION: "location",
    BROWSER: "browser",
    DATE: "date",
    COMPLETED: "completed"
}

export const CALL_STATUS_OPTIONS = [
    { value: STATUS_TYPES.BUSY, label: "Busy" },
    { value: STATUS_TYPES.FAILED, label: "Failed" },
    { value: STATUS_TYPES.COMPLETED, label: "Completed" },
    { value: STATUS_TYPES.IN_PROGRESS, label: "In Progress" },
    { value: STATUS_TYPES.NO_ANSWER, label: "No Answer" },
]

export const COMPLETED_OPTIONS = [
    { value: YES_AND_NO.YES, label: "Yes" },
    { value: YES_AND_NO.NO, label: "No" },
]

export const PROSPECT_FILTERED_COLUMNS_OBJECTS = {
    [PROSPECT_FILTERED_COLUMNS_NAMES.DATE]: {
        order: 1,
        comparator_value: "",
        value: "",
        label: "Date",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.DATE, // "date",
        component_type: FILTER_COMPONENT_TYPES.DATE_RANGE
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.CERTIFICATE_ID]: {
        order: 3,
        comparator_value: "",
        value: "",
        label: "Certificate ID",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.CERTIFICATE_ID, // "certificate_id",
        component_type: FILTER_COMPONENT_TYPES.TEXT
    },
  
    [PROSPECT_FILTERED_COLUMNS_NAMES.PAGE_URL]: {
        order: 5,
        comparator_value: "",
        value: "",
        label: "Page URL",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.PAGE_URL, // "page_url",
        component_type: FILTER_COMPONENT_TYPES.TEXT
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.BROWSER]: {
        order: 8,
        comparator_value: "",
        value: "",
        label: "Browser & OS",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.BROWSER, // "browser",
        component_type: FILTER_COMPONENT_TYPES.TEXT
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.IP_ADDRESS]: {
        order: 6,
        comparator_value: "",
        value: "",
        label: "IP Address",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.IP_ADDRESS, // "ip_address",
        component_type: FILTER_COMPONENT_TYPES.TEXT
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.CAMPAIGN_NAME]: {
        order: 2,
        comparator_value: "",
        value: "",
        label: "Campaign",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.CAMPAIGN_NAME, // "campaign_name",
        component_type: FILTER_COMPONENT_TYPES.MULTI_SELECT
    },

    [PROSPECT_FILTERED_COLUMNS_NAMES.LOCATION]: {
        order: 7,
        comparator_value: "",
        value: "",
        label: "Location",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.LOCATION, // "location",
        component_type: FILTER_COMPONENT_TYPES.TEXT
    },


    [PROSPECT_FILTERED_COLUMNS_NAMES.DURATION]: {
        order: 4,
        comparator_value: "",
        value: "",
        label: "Duration",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.DURATION, // "Call Duration",
        component_type: FILTER_COMPONENT_TYPES.COMPARATOR,
        options: COMPARISON_FIELD_OPTIONS,
        suffix: " sec"
    },
    [PROSPECT_FILTERED_COLUMNS_NAMES.COMPLETED]: {
        order: 9,
        comparator_value: "",
        value: "",
        label: "Completed",
        name: PROSPECT_FILTERED_COLUMNS_NAMES.COMPLETED, // "completed",
        component_type: FILTER_COMPONENT_TYPES.SINGLE_SELECT,
        options: COMPLETED_OPTIONS
    }
    
}
