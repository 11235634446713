
export const POST_DASHBOARD_MAIN_STATS = 'POST_DASHBOARD_MAIN_STATS';
export const POST_DASHBOARD_MAIN_STATS_SUCCESS = 'POST_DASHBOARD_MAIN_STATS_SUCCESS';
export const POST_DASHBOARD_MAIN_STATS_ERROR = 'POST_DASHBOARD_MAIN_STATS_ERROR';

export const POST_DASHBOARD_COMPARE_TO_MAIN_STATS = 'POST_DASHBOARD_COMPARE_TO_MAIN_STATS';
export const POST_DASHBOARD_COMPARE_TO_MAIN_STATS_SUCCESS = 'POST_DASHBOARD_COMPARE_TO_MAIN_STATS_SUCCESS';
export const POST_DASHBOARD_COMPARE_TO_MAIN_STATS_ERROR = 'POST_DASHBOARD_COMPARE_TO_MAIN_STATS_ERROR';

export const POST_DASHBOARD_MAIN_DETAIL_STATS = 'POST_DASHBOARD_MAIN_DETAIL_STATS';
export const POST_DASHBOARD_MAIN_DETAIL_STATS_SUCCESS = 'POST_DASHBOARD_MAIN_DETAIL_STATS_SUCCESS';
export const POST_DASHBOARD_MAIN_DETAIL_STATS_ERROR = 'POST_DASHBOARD_MAIN_DETAIL_STATS_ERROR';

export const POST_DASHBOARD_DEVICE_TYPE_STATS = 'POST_DASHBOARD_DEVICE_TYPE_STATS';
export const POST_DASHBOARD_DEVICE_TYPE_STATS_SUCCESS = 'POST_DASHBOARD_DEVICE_TYPE_STATS_SUCCESS';
export const POST_DASHBOARD_DEVICE_TYPE_STATS_ERROR = 'POST_DASHBOARD_DEVICE_TYPE_STATS_ERROR';

export const POST_DASHBOARD_BROWSER_TYPE_STATS = 'POST_DASHBOARD_BROWSER_TYPE_STATS';
export const POST_DASHBOARD_BROWSER_TYPE_STATS_SUCCESS = 'POST_DASHBOARD_BROWSER_TYPE_STATS_SUCCESS';
export const POST_DASHBOARD_BROWSER_TYPE_STATS_ERROR = 'POST_DASHBOARD_BROWSER_TYPE_STATS_ERROR';

export const POST_DASHBOARD_DOMAIN_STATS = 'POST_DASHBOARD_DOMAIN_STATS';
export const POST_DASHBOARD_DOMAIN_STATS_SUCCESS = 'POST_DASHBOARD_DOMAIN_STATS_SUCCESS';
export const POST_DASHBOARD_DOMAIN_STATS_ERROR = 'POST_DASHBOARD_DOMAIN_STATS_ERROR';

export const POST_DASHBOARD_STATE_STATS = 'POST_DASHBOARD_STATE_STATS';
export const POST_DASHBOARD_STATE_STATS_SUCCESS = 'POST_DASHBOARD_STATE_STATS_SUCCESS';
export const POST_DASHBOARD_STATE_STATS_ERROR = 'POST_DASHBOARD_STATE_STATS_ERROR';
