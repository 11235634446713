import React from "react";
import './LoadingSpinner.css'

const LoadingSpinner = ({spinner_div_id = "cc-status"}) => {
    return (
        <div id=""> 
            <div id={spinner_div_id}>
                <div className="cc-spinner-chase">
                    <div className="cc-spinner-chase-dot" />
                    <div className="cc-spinner-chase-dot" />
                    <div className="cc-spinner-chase-dot" />
                    <div className="cc-spinner-chase-dot" />
                    <div className="cc-spinner-chase-dot" />
                    <div className="cc-spinner-chase-dot" />
                </div>
            </div>
        </div>
    )
}

export default LoadingSpinner;