import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import SimpleBar from "simplebar-react"
import {
    Card,
    CardBody,
    Col,
    Row,
    UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/common/Breadcrumb"
import EditUserModal from "./UserEditModal"
import DeleteUserModal from "./UserDeleteModal"
import { getUsers, deleteUser, resetUserInviteSentTo, resendUserInvite } from "../../store/accounts/actions"
import filterFactory from "react-bootstrap-table2-filter"
import { USER_INVITE_STR, DEFAULT_START_TIME } from "helpers/constants"
import { CcCommaStringFormatter, dateCreatedFormatter, columnHeaderFormatter, tooltipForColumnHeader } from "helpers/utlis"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import InviteUserModal from "./UserInviteModal"
import ToastAlert from "components/common/ToastAlert/ToastAlert"
import { useSelector } from "react-redux"
import i18n_keys from "i18n_keys"
import { t } from "i18next"
import CustomSkeleton from "components/common/CustomSkeleton"
import { customSortCaret } from "helpers/bootstrap_table_helper"
import { defaultFormatter } from "utils/Formatter"


const Users = () => {

    const { users, invite_sent_to, current_user, loading } = useSelector(state => ({
        users: state.accounts.users,
        invite_sent_to: state.accounts.invite_sent_to,
        current_user: state.currentUserReducer.currentUser,
        loading: state.accounts.loading,
    }))

    const dispatch = useDispatch()

    const [deleteModal, setDeleteModal] = useState({
        active: false,
        first_name: "",
        last_name: "",
        email: "",
        id: ""
    })

    const [editUser, setEditUser] = useState({})
    const [editModal, setEditModal] = useState(false)
    const [inviteModal, setInviteModal] = useState(false)

    useEffect(() => {
        dispatch(getUsers())
    }, [dispatch])

    useEffect(() => {
        if (invite_sent_to) {
            ToastAlert.fire({ icon: 'success', title: `${USER_INVITE_STR.RESEND_SUCCESS_MSG}` })
        }
        dispatch(resetUserInviteSentTo())
    }, [invite_sent_to])

    const onClickResendInvite = (user) => {
        dispatch(resendUserInvite(user))
    }

    const columns = [
        {
            dataField: "first_name",
            text: "First Name",
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return columnHeaderFormatter(column, colIndex, { sortElement, filterElement }, column.text)
            },
            sort: true,
        },
        {
            dataField: "last_name",
            text: "Last Name",
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return columnHeaderFormatter(column, colIndex, { sortElement, filterElement }, column.text)
            },
            sort: true,
        },
        {
            dataField: "email",
            text: "Email",
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return columnHeaderFormatter(column, colIndex, { sortElement, filterElement }, column.text)
            },
            sort: true,
        },
        {
            dataField: 'last_sign_in_at',
            text: 'Last sign-in at',
            headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
                return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, _column.dataField, _column.text, t(i18n_keys.LAST_SIGN_IN_TOOLTIP_MESSAGE))
            },
            formatter: (cell) => (cell && cell != DEFAULT_START_TIME) ? dateCreatedFormatter(cell) : '--',
        },
        {
            dataField: 'sign_in_count',
            text: "Sign-in count",
            headerFormatter: (_column, colIndex, { sortElement, filterElement }) => {
                return tooltipForColumnHeader(_column, colIndex, { sortElement, filterElement }, _column.dataField, _column.text, t(i18n_keys.SIGN_IN_COUNT_TOOLTIP_MESSAGE))
            },
            splitHeader: true,
            formatter: CcCommaStringFormatter,
        },
        {
            dataField: "action",
            text: "Action",
            headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
                return columnHeaderFormatter(column, colIndex, { sortElement, filterElement }, column.text)
            },
            formatter: (cellContent, user) => {
                return (
                    <>
                        <div className="d-flex gap-3 justify-content-start">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() =>
                                    onClickEdit({
                                        first_name: user.first_name,
                                        last_name: user.last_name,
                                        email: user.email,
                                        id: user.id,
                                    })
                                }
                            >
                                <i className="mdi mdi-pencil font-size-18" id={`edittooltip-${user?.id}`} />
                                <UncontrolledTooltip placement="top" target={`edittooltip-${user?.id}`}>
                                    Edit
                                </UncontrolledTooltip>
                            </Link>
                            {!user.sign_in_count &&
                                <Link
                                    to="#"
                                    className="text-info"
                                    onClick={() => onClickResendInvite(user)}
                                >
                                    <i className="mdi mdi-email font-size-18" id={`resend_invite_tooltip-${user?.id}`} />
                                    <UncontrolledTooltip placement="top" target={`resend_invite_tooltip-${user?.id}`}>
                                        Resend Invite
                                    </UncontrolledTooltip>
                                </Link>
                            }
                            {current_user?.data?.id !== user?.id &&
                                <Link
                                    to="#"
                                    className="text-danger"
                                    onClick={() => onClickDelete(user)}
                                >
                                    <i className="mdi mdi-delete font-size-18" id={`deletetooltip-${user?.id}`} />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${user?.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            }
                        </div>
                    </>
                )
            }
        },
    ]

    const onClickDelete = user => {
        setDeleteModal({ ...user, active: true })
    }

    const handleDeleteUser = () => {
        dispatch(deleteUser(deleteModal?.id))
        setDeleteModal({ active: false, first_name: "", last_name: "", email: "", id: "" })
    }

    const onCloseDeleteModal = () => {
        setDeleteModal({ active: false, first_name: "", last_name: "", email: "", id: "" })
    }

    const onClickEdit = user => {
        setEditUser(user)
        setEditModal(true)
    }

    const onCloseEditModal = () => {
        setEditUser({})
        setEditModal(false)
    }

    const onCloseInviteModal = () => {
        setInviteModal(false)
    }

    const onClickInvite = () => {
        setInviteModal(true)
    }

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ]

    const { SearchBar } = Search

    return (
        <React.Fragment>
            <InviteUserModal
                show={inviteModal}
                onSendInviteClick={() => setInviteModal(false)}
                onCloseClick={onCloseInviteModal}
            />

            <EditUserModal
                show={editModal}
                user={editUser}
                onCloseClick={onCloseEditModal}
            />

            <DeleteUserModal
                user={deleteModal}
                onDeleteClick={handleDeleteUser}
                onCloseClick={onCloseDeleteModal}
            />
            <div className="page-content" id="page-content-wrapper">
                <MetaTags>
                    <title>{t(i18n_keys.USER_MANAGEMENT) + t(i18n_keys.PAGE_TITLE_SUFFIX)}</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Organization" breadcrumbItem="User Management" copyUrl={true} />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <ToolkitProvider
                                        keyField="id"
                                        columns={columns}
                                        data={users || []}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-2">
                                                    <Col xl="8" className="align-self-end">
                                                        <div className="mt-1">
                                                            <div className="search-box d-inline-block mb-1">
                                                                <div className="position-relative">
                                                                    <SearchBar
                                                                        className="rounded"
                                                                        {...toolkitProps.searchProps}
                                                                    />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xl="4" >
                                                    <div className="align-self-end float-end">
                                                            <button
                                                                className={`btn btn-primary mb-3 me-2`}
                                                                onClick={() => onClickInvite()}
                                                            >
                                                                <i className="mdi mdi-plus me-1" />
                                                                Add New User
                                                            </button>
                                                        </div>
                                                    </Col>  
                                                </Row>
                                                <Row>
                                                    <Col xl="12">
                                                        {loading ? <CustomSkeleton rows={8} cols={6} /> :
                                                            <div className="table-responsive">
                                                                <SimpleBar style={{ maxWidth: "100%" }}>
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        columns={columns}
                                                                        data={users || []}
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={
                                                                            "table align-middle table-nowrap text-black thead-h50"
                                                                        }
                                                                        headerClasses="text-black"
                                                                        rowClasses="tr-hover"
                                                                        headerWrapperClasses={"thead-light"}
                                                                        noDataIndication={t(i18n_keys.NO_USERS_TO_DISPLAY)}
                                                                        filter={filterFactory()}
                                                                        sort={{ sortCaret: customSortCaret }}
                                                                        {...toolkitProps.baseProps}
                                                                    />
                                                                </SimpleBar>
                                                            </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Users
