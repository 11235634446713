import React from 'react';
import ReactApexChart from 'react-apexcharts';

const PieChart = ({series, labels, colors}) => {
  var options = {
    chart: {
      width: '100%',
      type: 'pie',
      events: {} // Disables all chart events
    },
    colors: colors,
    labels: labels,
    theme: {
      monochrome: {
        enabled: false
      }
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5
        },
        expandOnClick: false
      }
    },
    tooltip: {
      enabled: false
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false
    }
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="pie"
      width="100%"
      height="130px"
    />
  );
};

export default PieChart;
