// actions.js
import * as consentActions from './actionTypes';

export const consentCertificateDetailsRequest = (consentData) => ({
  type: consentActions.CONSENT_CERTIFICATE_DETAILS_REQUEST,
  payload: consentData,
});

export const consentCertificateDetailsSuccess = (consentData) => ({
  type: consentActions.CONSENT_CERTIFICATE_DETAILS_SUCCESS,
  payload: consentData,
});

export const consentCertificateDetailsFailure = (error) => ({
  type: consentActions.CONSENT_CERTIFICATE_DETAILS_FAILURE,
  payload: error,
});
