import * as action from './actionTypes';

export const fetchCampaignEvents = (campaignData) => ({
  type: action.GET_CAMPAIGN_EVENTS,
  payload: campaignData,
});

export const fetchCampaignEventsSuccess = (payload) => ({
  type: action.GET_CAMPAIGN_EVENTS_SUCCESS,
  payload: payload
});

export const fetchCampaignEventsError = (error) => ({
  type: action.GET_CAMPAIGN_EVENTS_ERROR,
  payload: error,
});

export const fetchFilterdCampaignEvents = (campaignData) =>({
  type: action.GET_FILTERED_CAMPAIGN_EVENTS,
  payload: campaignData,
})
