//Phone regular expression for validating phone number
export const nameRegExp = /^([aA-zZ]+)\s?([a-zA-Z]+)$/
export const phoneRegExp = /(^\d{10}$)/
export const cityRegExp = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/
export const addressRegExp = /^[^\s]/
export const passwordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
export const zipcodeRegExp = /(^\d{5}$)/
export const urlRegExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const numberOnlyRegex = /[^0-9.]/g
export const IPRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/

//Error messages
export const STR_INVALID_EMAIL = "Please enter a valid email"
export const REQUIRED_ONE_EMAIL_OR_ACCOUNT = "Please select at least one account or Enter a valid email"
export const REQUIRED_ONE_USER = "Please select at least one user"
export const STR_INVALID_PHONE_NUMBER = "Please enter a valid phone number"
export const STR_INVALID_FIRST_NAME = "Please enter a valid first name"
export const STR_INVALID_LAST_NAME = "Please enter a valid last name"
export const STR_INVALID_CITY = "Please enter a valid city name"
export const STR_INVALID_ADDRESS = "Please enter a valid address"

export const STR_REQUIRED_FIRSTNAME = "Please enter your first name"
export const STR_REQUIRED_LASTNAME = "Please enter your last name"
export const STR_REQUIRED_PHONE_NUMBER = "Please enter your phone number"
export const STR_REQUIRED_COMPANY_NAME = "Please enter your company name"
export const STR_REQUIRED_ADDRESS = "Please enter your address"
export const STR_REQUIRED_CITY = "Please enter your city"
export const STR_REQUIRED_STATE = "Please select your state"
export const STR_REQUIRED_TIMEZONE = "Please select your timezone"
export const STR_REQUIRED_ZIP = "Please enter your zip"
export const STR_REQUIRED_PASSWORD = "Please enter your password"
export const STR_REQUIRED_CURRENT_PASSWORD = "Please enter your current password"
export const STR_REQUIRED_NEW_PASSWORD = "Please enter new password"

export const STR_REQUIRED_FIELD = "This field is mandatory"

export const STR_ALPHABETS_ONLY_FIRSTNAME = "Only alphabets are allowed for this field"
export const STR_ALPHABETS_ONLY_LASTNAME = "Only alphabets are allowed for this field"

export const STR_SHORT_FIRST_NAME =
  "Please enter a valid first name with atleast 3 characters"
export const STR_SHORT_LAST_NAME =
  "Please enter a valid last name with atleast 3 characters"
export const STR_PASSWORD_POLICY_STR =
  "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"


// Messages for error codes from API

export const errorMessages = {}
errorMessages["ERR_PARAMS_NOT_PERMITTED"] = ""
errorMessages["ERR_USER_INVALID"] = ""
errorMessages["ERR_USER_NOT_IN_ACCOUNT"] = "Forbidden action:"
errorMessages["ERR_USER_SAVE_FAILED"] =
  "Unable to save user details. Please try again later."
errorMessages["ERR_CAMPAIGN_SAVE_FAILED"] =
  "Unable to save the campaign details. Please try again later"
errorMessages["ERR_ACCOUNT_SAVE_FAILED"] =
  "Unable to save the account details. Please try again later"
errorMessages["ERR_ROLE_SAVE_FAILED"] =
  "Unable to create the role. Please try again later"

errorMessages["ERR_CAMPAIGN_INVALID"] = ""
errorMessages["ERR_ACCOUNT_INVALID"] = ""

errorMessages["ERR_PASSWORD_CONSTRAINT_VIOLATED"] = "This password may have been used recently. Please try a new password"
errorMessages["ERR_UNAUTHENTICATED"] = "Please login before proceeding."
errorMessages["ERR_UNAUTHORIZED"] =
  "Please check your permissions. You may not be permitted to do this action."
errorMessages["ERR_NOT_ALLOWED"] =
  "Feature disabled. Please contact your manager or admin"
errorMessages["ERR_FORBIDDEN"] = "Forbidden"

errorMessages["ERR_ACCOUNT_ALREADY_EXISTS"] = ""
errorMessages["ERR_CAMPAIGN_ALREADY_EXISTS"] = ""

errorMessages["ERR_NOT_FOUND="] = ""
errorMessages["ERR_USER_NOT_FOUND"] =
  "The user does not exist. Please check the details again."
errorMessages["ERR_CAMPAIGN_NOT_FOUND"] = ""
errorMessages["ERR_ACCOUNT_NOT_FOUND"] = ""
errorMessages["ERR_SRV_INTERNAL"] = "Internal Server Error"
errorMessages["ERR_SRV_EMAIL_SEND_FAILED"] = ""
errorMessages["ERR_SRV_NOT_IMPLEMENTED"] = ""
errorMessages["ERR_PASSWORD_MISMATCH"] = "The password you entered doesn't match your current password"
errorMessages["AUTHENTICATION_TOKEN_HAS_EXPIRED"] = "Your session expired. Please login again..."
errorMessages["ERR_DEVISE_SIGNATURE_VERIFICATION_FAILED"] = "Your session expired. Please login again..."
