import { REGISTER_USER, REGISTER_USER_SUCCESSFUL, REGISTER_USER_FAILED, SET_USER_TO_BE_CREATED } from './actionTypes';

const initialState = {
  loading: false,
  error: null,
  status: null,
  UserInfo: null,
};

const authRegister = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        loading: true,
        error: null,
        UserInfo: action.payload,
      };
    case REGISTER_USER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        status: action.payload,
      };
    case REGISTER_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_USER_TO_BE_CREATED:
      return {
        ...state,
        loading: false,
        UserInfo: action.payload,
      }
    default:
      return state;
  }
};

export default authRegister;
