import PropTypes from "prop-types"
import { React, useState, useEffect } from "react"
import { Col, Modal, Row, Form, Label, Input, FormFeedback } from "reactstrap"
//redux
import { useDispatch } from "react-redux"
import { addNewUser, getUsers } from '../../store/accounts/actions'

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import * as CONSTANTS from "../../common/utils/data_validations"
import { useSelector } from "react-redux"

const InviteUserModal = ({ show, onSendInviteClick, onCloseClick }) => {
  const dispatch = useDispatch()
  const [existUserEmail, setExistUserEmail] = useState(false)

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  const { users } = useSelector(state => ({
    users: state.accounts.users,
  }))

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(CONSTANTS.STR_INVALID_EMAIL),
      first_name: Yup.string()
        .required(CONSTANTS.STR_REQUIRED_FIRSTNAME)
        .matches(
          CONSTANTS.nameRegExp,
          CONSTANTS.STR_INVALID_FIRST_NAME)
        .min(2),
      last_name: Yup.string()
        .required(CONSTANTS.STR_REQUIRED_LASTNAME)
        .matches(
          CONSTANTS.nameRegExp,
          CONSTANTS.STR_INVALID_LAST_NAME
        )
        .min(2),
    }),
    onSubmit: values => {
      let params = values
      dispatch(addNewUser(params))
      onSendInviteClick()
      validation.handleReset()
    },
  })

  useEffect(() => {
    if (users) {
      const exUserEmail = users?.filter(usr => usr?.email === validation.values.email) || [];
      if (exUserEmail.length > 0) {
        setExistUserEmail(true)
      } else {
        if (existUserEmail != false) {
          setExistUserEmail(false)
        }
      }
    }
  }, [validation.values.email])

  const onCloseClickModal = () => {
    onCloseClick()
    validation.handleReset()
  }

  return (
    <Modal isOpen={show} toggle={onCloseClickModal} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title text-black mt-0" id="myModalLabel">
          Add New User
        </h5>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onCloseClickModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <div className="modal-body noscrollbar">
          <Row>
            <Col xl={6}>
              <div className="mb-3">
                <Label className='form-label'>First Name</Label>
                <Input
                  type="text"
                  name="first_name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.first_name || ""}
                  invalid={
                    validation.touched.first_name &&
                      validation.errors.first_name
                      ? true
                      : false
                  }
                ></Input>
                {validation.touched.first_name &&
                  validation.errors.first_name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.first_name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xl={6}>
              <div className="mb-3">
                <Label className='form-label'>Last Name</Label>
                <Input
                  type="text"
                  name="last_name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.last_name || ""}
                  invalid={
                    validation.touched.last_name &&
                      validation.errors.last_name
                      ? true
                      : false
                  }
                ></Input>
                {validation.touched.last_name &&
                  validation.errors.last_name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.last_name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xl={12}>
              <div className="mb-3">
                <Label className='form-label'>
                  Enter user email
                </Label>
                <Input
                  type="text"
                  className={`form-control ${existUserEmail ? "is-invalid" : ""}`}
                  id=""
                  placeholder="e.g. user@example.com"
                  name="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email &&
                      validation.errors.email
                      ? true
                      : false
                  }
                ></Input>
                <div className="invalid-feedback">
                  {!validation.errors.email && 'This email is already taken.'}
                </div>
                {validation.touched.email &&
                  validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={onCloseClickModal}
            className="btn btn-light "
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`btn btn-primary`}
            disabled={existUserEmail}
          >
            Send Invite
          </button>
        </div>
      </Form>
    </Modal>
  )
}

InviteUserModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSendInviteClick: PropTypes.func,
  show: PropTypes.any,
}

export default InviteUserModal
