// actionTypes.js
export const SAVE_CAMPAIGN_REQUEST = 'SAVE_CAMPAIGN_REQUEST';
export const SAVE_CAMPAIGN_SUCCESS = 'SAVE_CAMPAIGN_SUCCESS';
export const SAVE_CAMPAIGN_FAILURE = 'SAVE_CAMPAIGN_FAILURE';
export const FETCH_CAMPAIGNS_REQUEST = 'FETCH_CAMPAIGNS_REQUEST';
export const FETCH_CAMPAIGNS_SUCCESS = 'FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CAMPAIGNS_FAILURE = 'FETCH_CAMPAIGNS_FAILURE';
export const FETCH_CAMPAIGN_STATS_REQUEST = 'FETCH_CAMPAIGN_STATS_REQUEST';
export const FETCH_CAMPAIGN_STATS_SUCCESS = 'FETCH_CAMPAIGN_STATS_SUCCESS';
export const FETCH_CAMPAIGN_STATS_FAILURE = 'FETCH_CAMPAIGN_STATS_FAILURE';
export const GET_CAMPAIGN_BY_ID_REQUEST = 'GET_CAMPAIGN_BY_ID_REQUEST';
export const GET_CAMPAIGN_BY_ID_SUCCESS = 'GET_CAMPAIGN_BY_ID_SUCCESS';
export const GET_CAMPAIGN_BY_ID_FAILURE = 'GET_CAMPAIGN_BY_ID_FAILURE';
export const UPDATE_CAMPAIGN_REQUEST = 'UPDATE_CAMPAIGN_REQUEST';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_FAILURE = 'UPDATE_CAMPAIGN_FAILURE';
export const CAMPAIGN_STATS_COMPARED_DATA_REQUEST = 'CAMPAIGN_STATS_COMPARED_DATA_REQUEST';
export const CAMPAIGN_STATS_COMPARED_DATA_SUCCESS = 'CAMPAIGN_STATS_COMPARED_DATA_SUCCESS';
export const CAMPAIGN_STATS_COMPARED_DATA_FAILURE = 'CAMPAIGN_STATS_COMPARED_DATA_FAILURE';