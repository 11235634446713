import { useEffect, useRef } from 'react';

// Make React useEffect hook not run on initial render
// https://stackoverflow.com/a/57941438/7550665
const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}

export default useDidMountEffect;
