import React, { useState } from 'react';
import PropTypes from "prop-types"

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import ReactSelect from 'react-select';

import {
  addDays,
  format,
  isEqual,
  subMilliseconds,
  subDays,
  subWeeks,
  subMonths,
  subQuarters,
  subYears,
  addHours
} from 'date-fns';

import {
  BROWSER_IANA_TIMEZONE,
  getZonedEndOfDay,
  getZonedStartOfDay,
  getZonedStartOfMonth,
  getZonedStartOfQuarter,
  getZonedStartOfWeek,
  getZonedStartOfYear
} from 'helpers/time_utils';

import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import useDidMountEffect from 'hooks/useDidMount';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
// import { getPrimaryButtonClass } from 'helpers/sf_utils';

export const DATE_RANGE_OPTIONS = {
  ALL_TIME: 'ALL_TIME',
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  THIS_WEEK: 'THIS_WEEK',
  LAST_WEEK: 'LAST_WEEK',
  LAST_7_DAYS: 'LAST_7_DAYS',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  LAST_30_DAYS: 'LAST_30_DAYS',
  THIS_QUARTER: 'THIS_QUARTER',
  LAST_QUARTER: 'LAST_QUARTER',
  THIS_YEAR: 'THIS_YEAR',
  LAST_YEAR: 'LAST_YEAR',
  CUSTOM_RANGE: 'CUSTOM_RANGE'
}

export const DATE_RANGE_OPTIONS_DISPLAY = {
  ALL_TIME: 'All Time',
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  THIS_WEEK: 'This Week',
  LAST_WEEK: 'Last Week',
  LAST_7_DAYS: 'Last 7 Days',
  THIS_MONTH: 'This Month',
  LAST_MONTH: 'Last Month',
  LAST_30_DAYS: 'Last 30 Days',
  THIS_QUARTER: 'This Quarter',
  LAST_QUARTER: 'Last Quarter',
  THIS_YEAR: 'This Year',
  LAST_YEAR: 'Last Year',
  CUSTOM_RANGE: 'Custom Range'
}

const CUSTOM_RANGE_LABEL = 'Custom Range'
const CALENDAR_SELECTION_KEY = 'calendar_selection'

/**
 * 
 * @param {Date} dt start of day
 * @param {String} ianaTz
 * @returns start of day after considering DST
 */
const getStartOfDayHandleDST = (dt, ianaTz) => {
  return getZonedStartOfDay(addHours(dt, 2), ianaTz);
}

const getStartAndEndDateForLabel = (label, today, ianaTz) => {
  let startDate = null;
  let endDate = null;
  switch (label) {
    case DATE_RANGE_OPTIONS.TODAY:
      startDate = getZonedStartOfDay(today, ianaTz);
      endDate = getZonedEndOfDay(today, ianaTz);
      break;
    case DATE_RANGE_OPTIONS.YESTERDAY:
      const yesterdayDate = subDays(today, 1);
      startDate = getZonedStartOfDay(yesterdayDate, ianaTz);
      endDate = getZonedEndOfDay(yesterdayDate, ianaTz);
      break;
    case DATE_RANGE_OPTIONS.THIS_WEEK:
      startDate = getZonedStartOfWeek(today, ianaTz);
      endDate = getZonedEndOfDay(today, ianaTz);
      break;
    case DATE_RANGE_OPTIONS.LAST_WEEK:
      const startOfThisWeek = getZonedStartOfWeek(today, ianaTz);
      startDate = getStartOfDayHandleDST(subWeeks(startOfThisWeek, 1), ianaTz);
      endDate = subMilliseconds(startOfThisWeek, 1);
      break;
    case DATE_RANGE_OPTIONS.LAST_7_DAYS:
      // XXX: we subtract only 6 days since the current day is also included
      startDate = getStartOfDayHandleDST(subDays(getZonedStartOfDay(today, ianaTz), 6), ianaTz);
      endDate = getZonedEndOfDay(today, ianaTz);
      break;
    case DATE_RANGE_OPTIONS.THIS_MONTH:
      startDate = getZonedStartOfMonth(today, ianaTz);
      endDate = getZonedEndOfDay(today, ianaTz);
      break;
    case DATE_RANGE_OPTIONS.LAST_MONTH:
      const startOfThisMonth = getZonedStartOfMonth(today, ianaTz);
      startDate = getStartOfDayHandleDST(subMonths(startOfThisMonth, 1), ianaTz);
      endDate = subMilliseconds(startOfThisMonth, 1);
      break;
    case DATE_RANGE_OPTIONS.LAST_30_DAYS:
      // we subtract only 29 days since the current day is also included
      startDate = getStartOfDayHandleDST(subDays(getZonedStartOfDay(today, ianaTz), 29), ianaTz);
      endDate = getZonedEndOfDay(today, ianaTz);
      break;
    case DATE_RANGE_OPTIONS.THIS_QUARTER:
      startDate = getZonedStartOfQuarter(today, ianaTz);
      endDate = getZonedEndOfDay(today, ianaTz);
      break;
    case DATE_RANGE_OPTIONS.LAST_QUARTER:
      const startOfThisQuarter = getZonedStartOfQuarter(today, ianaTz);
      startDate = getStartOfDayHandleDST(subQuarters(startOfThisQuarter, 1), ianaTz);
      endDate = subMilliseconds(startOfThisQuarter, 1);
      break;
    case DATE_RANGE_OPTIONS.THIS_YEAR:
      startDate = getZonedStartOfYear(today, ianaTz);
      endDate = getZonedEndOfDay(today, ianaTz);
      break;
    case DATE_RANGE_OPTIONS.LAST_YEAR:
      const startOfThisYear = getZonedStartOfYear(today, ianaTz);
      startDate = getStartOfDayHandleDST(subYears(startOfThisYear, 1), ianaTz);
      endDate = subMilliseconds(startOfThisYear, 1);
      break;
    case DATE_RANGE_OPTIONS.ALL_TIME:
      startDate = null
      endDate = null
      break;
    case DATE_RANGE_OPTIONS.CUSTOM_RANGE:
      startDate = CUSTOM_RANGE_LABEL;
      endDate = CUSTOM_RANGE_LABEL;
      break;
    default:
      startDate = today;
      endDate = today;
      break;
  }

  return { startDate, endDate };
}

export const createOption = (label, today = new Date(), ianaTz = BROWSER_IANA_TIMEZONE) => {
  if (ianaTz === undefined || ianaTz === null) {
    ianaTz = BROWSER_IANA_TIMEZONE;
  }
  return {
    label: DATE_RANGE_OPTIONS_DISPLAY[label],
    value: getStartAndEndDateForLabel(label, today, ianaTz),
    original_label: label,
    ianaTz: ianaTz
  }
}

export const createOptionWithDates = (label, start_date, end_date) => {
  return {
    label: label,
    value: { start_date, end_date },
    original_label: label,
    ianaTz: BROWSER_IANA_TIMEZONE
  }
}

export const createLabelWithDateRange = ({ label, startDate, endDate },
  ianaTz = BROWSER_IANA_TIMEZONE) => {
  if (ianaTz === undefined || ianaTz === null) {
    ianaTz = BROWSER_IANA_TIMEZONE;
  }
  if (label === DATE_RANGE_OPTIONS_DISPLAY.ALL_TIME || !startDate || !endDate) {
    return label;
  }

  if (isEqual(getZonedStartOfDay(startDate, ianaTz), getZonedStartOfDay(endDate, ianaTz))) {
    return label.length > 0 ? `${label}: ${format(utcToZonedTime(startDate, ianaTz), 'MMM d, yyyy')}` :
      `${format(utcToZonedTime(startDate, ianaTz), 'MMM d, yyyy')}`
  }
  if (label.length > 0) {
    return `${label}: ${format(utcToZonedTime(startDate, ianaTz), 'MMM d')} - ${format(utcToZonedTime(endDate, ianaTz), 'MMM d, yyyy')}`
  }
  else {
    return `${format(utcToZonedTime(startDate, ianaTz), 'MMM d')} - ${format(utcToZonedTime(endDate, ianaTz), 'MMM d, yyyy')}`

  }
}

const CustomDateRangePicker = (props) => {
  // maintain two states of selected date, one from drop down and another from actual react-date-range
  // call props.onChange when a date range is selected
  const [selectedOption, setSelectedOption] = useState(props.value)
  const [calendarRangeState, setCalendarRangeState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: CALENDAR_SELECTION_KEY
  })
  const [showCalendar, setShowCalendar] = useState(false)
  const tz = props.ianaTz || BROWSER_IANA_TIMEZONE;

  const handleSelectedOption = (opt) => {
    setSelectedOption({ ...opt });
  }

  useDidMountEffect(() => {
    if (selectedOption.value.startDate !== CUSTOM_RANGE_LABEL) {
      props.onChange({
        label: createLabelWithDateRange(
          {
            label: selectedOption.label,
            startDate: selectedOption.value.startDate,
            endDate: selectedOption.value.endDate
          },
          tz,
        ),
        value: selectedOption.value,
        original_label: selectedOption.original_label,
        ianaTz: tz
      })
      return;
    }

    setShowCalendar(true)
  }, [selectedOption])

  const handleCalendarApply = () => {
    const startDateFromCalendarWithTz = zonedTimeToUtc(new Date(calendarRangeState.startDate.toDateString()), tz);
    const endDateFromCalendarWith = subMilliseconds(addDays(zonedTimeToUtc(new Date(calendarRangeState.endDate.toDateString()), tz), 1), 1);

    const newOption = {
      label: createLabelWithDateRange({ label: CUSTOM_RANGE_LABEL, startDate: startDateFromCalendarWithTz, endDate: endDateFromCalendarWith }, tz),
      value: { startDate: startDateFromCalendarWithTz, endDate: endDateFromCalendarWith },
      original_label: DATE_RANGE_OPTIONS.CUSTOM_RANGE,
      ianaTz: tz
    }

    props.onChange(newOption)
    setShowCalendar(false)
  }

  return (
    <>
      {showCalendar && (
        <>
          <Modal size='xl' isOpen={showCalendar} toggle={() => setShowCalendar(false)} centered={true}>
            <ModalBody className="py-3 px-5" >
              <DateRangePicker
                onChange={(val) => setCalendarRangeState(val[CALENDAR_SELECTION_KEY])}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={[calendarRangeState]}
                editableDateInputs={false}
                direction="horizontal"
                className="w-100"
              />
            </ModalBody>
            <ModalFooter>
              <Button onClick={handleCalendarApply} color="primary" type='button'>Apply</Button>
            </ModalFooter>
          </Modal>
        </>
      )}

      <ReactSelect
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        value={props.value}
        options={props.options}
        onChange={handleSelectedOption}
        blurInputOnSelect={true}
        maxMenuHeight={600}
        classNamePrefix='select2-selection'
      />
    </>
  )
}

CustomDateRangePicker.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  ianaTz: PropTypes.string
}

export default CustomDateRangePicker;
