import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import states10m from 'us-atlas/states-10m.json';
import { topojson, ChoroplethChart, ColorLogarithmicScale } from 'chartjs-chart-geo';
import { Tooltip } from 'chart.js';
import _ from 'lodash';
// import _ from 'lodash';

// TODO: BM-2750 On resizing, canvas becomes small but doesn't big

export const UsStateNameToStateCode = {
  "Alabama": "AL",
  "Alaska": "AK",
  "Arizona": "AZ",
  "Colorado": "CO",
  "Florida": "FL",
  "Georgia": "GA",
  "Indiana": "IN",
  "Kansas": "KS",
  "Maine": "ME",
  "Massachusetts": "MA",
  "Minnesota": "MN",
  "New Jersey": "NJ",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Oklahoma": "OK",
  "Pennsylvania": "PA",
  "South Dakota": "SD",
  "Texas": "TX",
  "Wyoming": "WY",
  "Connecticut": "CT",
  "Missouri": "MO",
  "West Virginia": "WV",
  "Illinois": "IL",
  "New Mexico": "NM",
  "Arkansas": "AR",
  "California": "CA",
  "Delaware": "DE",
  "District of Columbia": "DC",
  "Hawaii": "HI",
  "Iowa": "IA",
  "Kentucky": "KY",
  "Maryland": "MD",
  "Michigan": "MI",
  "Mississippi": "MS",
  "Montana": "MT",
  "New Hampshire": "NH",
  "New York": "NY",
  "Ohio": "OH",
  "Oregon": "OR",
  "Tennessee": "TN",
  "Utah": "UT",
  "Virginia": "VA",
  "Washington": "WA",
  "Wisconsin": "WI",
  "American Samoa": "AS",
  "Guam": "GU",
  "Commonwealth of the Northern Mariana Islands": "MP",
  "Nebraska": "NE",
  "South Carolina": "SC",
  "Puerto Rico": "PR",
  "United States Virgin Islands": "VI",
  "Idaho": "ID",
  "Nevada": "NV",
  "Vermont": "VT",
  "Louisiana": "LA",
  "Rhode Island": "RI"
}

export const UsStateCodes = Object.values(UsStateNameToStateCode);

const tooltipLabel = (tooltipItem) => {
  return _.flatten(tooltipItem?.raw.dataLabel.data)
    .map(item => `${item.key}: ${item.value}`)
    .join('  ');
};

const tooltipTitle = (tooltipItems) => {
  return tooltipItems[0]?.raw?.dataLabel?.header;
}

const StateMap = ({ mapData, tooltipCardHeight = 100, tooltipCardWidth = 320 }) => {
  const nation = topojson.feature(states10m, states10m.objects.nation).features[0];
  const states = topojson.feature(states10m, states10m.objects.states).features;
  const canvasRef = useRef(null);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
  const dataLabel = tooltipData?.dataLabel;
  const stateDataMapData = {
    labels: states.map((d) => d.properties.name),
    datasets: [
      {
        label: 'States',
        outline: nation,
        data: states.map((d) => {
          const stateName = d.properties.name;
          const singleStateData = mapData.find(elem => elem.state_code === UsStateNameToStateCode?.[stateName]);
          const dataLabel = singleStateData.dataLabel;

          return {
            feature: d,
            value: singleStateData.value,
            dataLabel: dataLabel,
          }
        })
      },
    ],
  };

  const clearToolTip = () => {
    setTooltipData(null);
  }

  ChoroplethChart.register(ColorLogarithmicScale);
  ChoroplethChart.register(Tooltip);

  const updateNewTooltipPosition = (tooltip, chart) => {
    let newTooltipPosition = { left: tooltip.caretX, top: tooltip.caretY, width: tooltipCardWidth, height: tooltipCardHeight };
    const canvasWidth = chart.canvas.clientWidth;
    const canvasHeight = chart.canvas.clientHeight;

    if (newTooltipPosition.left + newTooltipPosition.width > canvasWidth) {
      newTooltipPosition.left -= newTooltipPosition.width;
    }

    if (newTooltipPosition.top + newTooltipPosition.height > canvasHeight) {
      newTooltipPosition.top -= newTooltipPosition.height;
    }
    setTooltipPosition(_.cloneDeep(newTooltipPosition));
  }

  const externalTooltipHandler = (context) => {
    const { tooltip, chart } = context;
    if (tooltip.opacity === 0) {
      clearToolTip();
      return;
    }
    const data = tooltip.dataPoints[0];
    const dataIndex = data.dataIndex;
    if (dataIndex !== tooltipData?.dataIndex) {
      const newTooltipData = _.cloneDeep(data.dataset.data[dataIndex]);
      setTooltipData({ ...newTooltipData, dataIndex: dataIndex });

      updateNewTooltipPosition(tooltip, chart);
    }
  }

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      const chart = new ChoroplethChart(ctx, {
        data: stateDataMapData,
        options: {
          // borderColor: 'white', // TODO
          scales: {
            projection: {
              axis: 'x',
              projection: 'albersUsa',
            },
            color: {
              type: "colorLogarithmic",
              axis: 'x',
              quantize: 5,
              legend: {
                position: "top-right",
                align: 'right',
                margin: 20
              },
            },
          },
          elements: {
            geoFeature: {
              hoverBackgroundColor: (ctx) => {
                return ctx.element.options.backgroundColor;
              }
            }
          },
          plugins: {
            tooltip: {
              enabled: false,
              position: 'nearest',
              external: externalTooltipHandler,
            }
          }
        },
      });

      // Cleanup function to destroy chart when component unmounts
      return () => {
        chart.destroy();
      };
    }
  }, [states, nation, mapData]);


  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col xl="12">
              <div
                id="chartjs-map-tooltip"
                class="center"
                style={{
                  opacity: (tooltipData != null) ? 1 : 0,
                  position: 'absolute',
                  pointerEvents: 'none',
                  ...tooltipPosition
                }}>
                <Card className='shadow-lg'>
                  <CardHeader>
                    <h5 className='text-black'>
                      {dataLabel?.header || ''}
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {dataLabel?.data?.map((items, index) => (
                        <Col key={index} md={12}>
                          <Row>
                            {items.map((item, index) => (
                              <Col md={12 / items.length} key={index}>
                                <span className='text-black'>{item.key}</span>
                                <h5 className='text-black'>{item.value}</h5>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </CardBody>
        <canvas ref={canvasRef} onMouseLeave={clearToolTip}></canvas>
      </Card>
    </>
  )
}

export default StateMap;
