import { takeLatest, call, put } from 'redux-saga/effects';
import { loginSuccess, loginFailure } from './actions';
import { login } from '../../../utils/Api';
import { LOGIN_REQUEST } from './actionTypes';

export function* handleLogin(action) {
  const { email, password, navigate } = action.payload;
  try {
    const userData = yield call(login, email, password);
    localStorage.setItem('authToken', (userData.access_token));
    yield put(loginSuccess(userData));
    navigate("/campaigns")
  } catch (error) {
    yield put(loginFailure(error.message));
  }
}

export function* loginSaga() {
  yield takeLatest(LOGIN_REQUEST, handleLogin);
}