import React, { useEffect, useRef } from 'react';
import rrwebPlayer from 'rrweb-player';
import 'rrweb-player/dist/style.css';
import './ReplayerComponent.css';

const ReplayerComponent = ({ events, width, height }) => {
  const replayContainerRef = useRef(null);

  useEffect(() => {
    let replayerInstance;

    if (replayContainerRef.current && events.length >= 2) {
      replayerInstance = new rrwebPlayer({
        target: replayContainerRef.current,
        props: {
          events: events,
          width: width,
          height: height,
        }
      });

      replayerInstance.play();
    }

    return () => {
      if (replayContainerRef.current) {
        replayContainerRef.current.innerHTML = '';
      }
    };
  }, [events]);

  return <div ref={replayContainerRef} data-testid="replay-container" />;
};

export default ReplayerComponent;
