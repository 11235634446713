import LabelWithTooltip from "components/common/LabelWithTooltip";
import React from "react";

const ShareAndCopyUrl = ({ copyButtonRef, copyToClipboard, tooltipMessage }) => {
  return (
    <div style={{ marginLeft: 'auto', marginRight: '29px', display: 'flex', alignItems: 'flex-start' }}>
      <LabelWithTooltip
        name='Share Certificate'
        tooltip_message={tooltipMessage}
        target='share-certificate'
        is_active={true}
      />
      <span
        ref={copyButtonRef}
        onClick={copyToClipboard}
        style={{ cursor: 'pointer', color: '#2FB4E9', marginLeft: '10px' }}
      >
        Copy Certificate URL
      </span>
    </div>
  );
};

export default ShareAndCopyUrl;
