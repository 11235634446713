import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, CardBody, Card, Label, Input, Container, FormFeedback, Form } from "reactstrap";
import { Link } from "react-router-dom";
import logoDark from "assets/images/logo-dark.png";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { registerRequest, setUserToBeCreated } from "../../store/auth/register/actions";
import { useNavigate } from 'react-router-dom';
import { us_cities } from "../../components/common/data/us_cities";
import { us_states } from "../../components/common/data/us_states";
import * as CONSTANTS from "../../common/utils/data_validations";
import { useFormik } from "formik"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import { AppConfiguration } from "config";

const Register = () => {
  const [errors] = useState({});

  let state_names = []
  let city_names = []
  us_states.map(e => {
    state_names.push({ label: e.isocode, value: e.name })
  })
  us_cities.map(e => {
    city_names.push({ label: e.city, value: e.city })
  })

  const optionGroupStates = [
    {
      label: "Select State",
      options: state_names,
    },
  ]

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = !!localStorage.getItem('authToken');
  const { registrationError, registrationStatus, userInfo } = useSelector(state => ({
    registrationError: state.authRegister.error,
    registrationStatus: state.authRegister.status,
    userInfo: state.authRegister.UserInfo,
  }));

  const selectedUserInfo = useMemo(() => {
    return userInfo;
  }, [userInfo])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: selectedUserInfo?.email || "",
      first_name: selectedUserInfo?.first_name || "",
      last_name: selectedUserInfo?.last_name || "",
      password: selectedUserInfo?.password || "",
      phone: selectedUserInfo?.phone || "",
      address: selectedUserInfo?.address || "",
      city: selectedUserInfo?.city || "",
      state: selectedUserInfo?.state ? {
        label: state_names.
          find(e => e.label === selectedUserInfo?.state).label,
        value: state_names.
          find(e => e.label === selectedUserInfo?.state).value
      } : {},
      zip_code: selectedUserInfo?.zip_code || "",
      company_name: selectedUserInfo?.company_name || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email()
        .required(CONSTANTS.STR_INVALID_EMAIL)
        .matches(
          CONSTANTS.emailRegex,
          CONSTANTS.STR_INVALID_EMAIL),
      first_name: Yup.string()
        .required(CONSTANTS.STR_REQUIRED_FIRSTNAME)
        .matches(
          CONSTANTS.nameRegExp,
          CONSTANTS.STR_INVALID_FIRST_NAME)
        .min(2),
      last_name: Yup.string()
        .required(CONSTANTS.STR_REQUIRED_LASTNAME)
        .matches(
          CONSTANTS.nameRegExp,
          CONSTANTS.STR_INVALID_LAST_NAME
        )
        .min(2),
      phone: Yup.string()
        .matches(
          CONSTANTS.phoneRegExp,
          CONSTANTS.STR_INVALID_PHONE_NUMBER
        )
        .required(CONSTANTS.STR_REQUIRED_PHONE_NUMBER),
      address: Yup.string()
        .required(CONSTANTS.STR_REQUIRED_ADDRESS)
        .min(3)
        .matches(
          CONSTANTS.addressRegExp,
          CONSTANTS.STR_INVALID_ADDRESS
        ),
      city: Yup.string()
        .required(CONSTANTS.STR_REQUIRED_CITY)
        .matches(
          CONSTANTS.cityRegExp,
          CONSTANTS.STR_INVALID_CITY
        )
        .min(2),
      state: Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required(),
      }),
      zip_code: Yup.string()
        .required(CONSTANTS.STR_REQUIRED_ZIP)
        .matches(CONSTANTS.zipcodeRegExp, "Please enter a valid zip code"),
      password: Yup.string()
        .matches(CONSTANTS.passwordRegExp, CONSTANTS.STR_PASSWORD_POLICY_STR),
      company_name: Yup.string().required(CONSTANTS.STR_REQUIRED_COMPANY_NAME),
    }),
    onSubmit: values => {
      let submitValues = JSON.parse(JSON.stringify(values));
      submitValues.state = values.state.label
      submitValues.zip_code = parseInt(values.zip_code, 10);
      dispatch(setUserToBeCreated(submitValues))
      if (Object.keys(validation.errors).length === 0) {
        dispatch(registerRequest(submitValues));
      }
    }
  })

  useEffect(() => {
    if (registrationStatus === 'success') {
      navigate('/login?registration=success');
    }
    else if (isLoggedIn) {
      navigate('/campaigns');
    }
  }, [registrationStatus, isLoggedIn, navigate]);

  return (
    <React.Fragment>
      <div className="account-pages mt-1 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={6}>
              <Card className="overflow-hidden">
                <div className="bg-white bg-soft">
                  <Row>
                    <Col xs={12}>
                      <div className="text-center p-4">
                        <img src={logoDark} height={70} alt="Logo" />
                      </div>
                      <div className="text-dark px-4">
                        <Label className="label-title font-weight-semibold text-black" style={{ fontSize: '1rem' }}>Create an account</Label>
                        <p>
                          Create your account below or
                          if you need assistance contact us at 877-323-7750
                        </p>
                      </div>
                      <div className="text-dark px-4">
                        <p style={{ fontSize: '1rem', color: "#34c38f", fontWeight: 600 }}>Account Information</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {registrationError && (
                    <div className="alert alert-danger" role="alert">
                      {registrationError}
                    </div>
                  )}
                  <div className="p-2">
                    <Form className="form-horizontal" onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}>
                      <Row>
                        <Col xl={6}>
                          <div className="mb-3">
                            <Label className="form-label font-weight-semibold">First Name</Label>
                            <Input
                              name="first_name"
                              className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.first_name || ""}
                              invalid={
                                validation.touched.first_name &&
                                  validation.errors.first_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.first_name &&
                              validation.errors.first_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.first_name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <Label className="form-label font-weight-semibold">Last Name</Label>
                            <Input
                              name="last_name"
                              className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.last_name || ""}
                              invalid={
                                validation.touched.last_name &&
                                  validation.errors.last_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.last_name &&
                              validation.errors.last_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.last_name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <Label className="form-label font-weight-semibold">Phone</Label>
                            <NumberFormat
                              name="phone"
                              format="###-###-####"
                              type="text"
                              mask="_"
                              className={validation.touched.phone && validation.errors.phone ? "is-invalid form-control" : "form-control"}
                              onValueChange={(values) => {
                                const { value } = values;
                                validation.setFieldValue('phone', value);
                              }}
                              onBlur={validation.handleBlur}
                              value={validation.values.phone || ""}
                              invalid={validation.touched.phone && validation.errors.phone ? "true" : "false"}
                              aria-invalid={validation.touched.phone && validation.errors.phone ? "true" : "false"}
                            />
                            {validation.touched.phone &&
                              validation.errors.phone ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phone}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <Label className="form-label font-weight-semibold">Company Name</Label>
                            <Input
                              name="company_name"
                              className={`form-control ${errors.company_name ? 'is-invalid' : ''}`}
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.company_name || ""}
                              invalid={
                                validation.touched.company_name &&
                                  validation.errors.company_name
                                  ? true
                                  : false
                              }
                            ></Input>
                            {validation.touched.company_name
                              && validation.errors.company_name ? (
                              <small className="text-danger">
                                {validation.errors.company_name}
                              </small>
                            ) : null}
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <Label className="form-label font-weight-semibold">Address</Label>
                            <Input
                              name="address"
                              className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.address || ""}
                              invalid={
                                validation.touched.address &&
                                  validation.errors.address
                                  ? true
                                  : false
                              }
                            ></Input>
                            {validation.touched.address &&
                              validation.errors.address ? (
                              <FormFeedback type="invalid">
                                {validation.errors.address}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <Label className="form-label font-weight-semibold">City</Label>
                            <Input
                              name="city"
                              type="text"
                              className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.city || ""}
                              invalid={
                                validation.touched.city &&
                                  validation.errors.city
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.city &&
                              validation.errors.city ? (
                              <FormFeedback type="invalid">
                                {validation.errors.city}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <Label className="form-label font-weight-semibold">State</Label>
                            <Select
                              options={optionGroupStates}
                              classNamePrefix="select2-selection"
                              onChange={selectedOption => {
                                let event = {
                                  target: {
                                    name: "state",
                                    value: selectedOption,
                                  },
                                }
                                validation.handleChange(event)
                              }}
                              onBlur={() => {
                                validation.handleBlur({
                                  target: { name: "state" },
                                })
                              }}
                              value={validation.values.state || ""}
                              invalid={
                                validation.touched.state &&
                                  validation.errors.state
                                  ? true
                                  : false
                              }
                              menuPosition="fixed"
                            />
                            {validation.touched.state &&
                              validation.errors.state ? (
                              <small className="text-danger">
                                {CONSTANTS.STR_REQUIRED_STATE}
                              </small>
                            ) : null}
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <Label className="form-label font-weight-semibold">Zip Code</Label>
                            <Input
                              name="zip_code"
                              type="zip_code"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.zip_code || ""}
                              invalid={
                                validation.touched.zip_code &&
                                  validation.errors.zip_code
                                  ? true
                                  : false
                              }
                            ></Input>
                            {validation.touched.zip_code &&
                              validation.errors.zip_code ? (
                              <FormFeedback type="invalid">
                                {validation.errors.zip_code}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <Label className="form-label font-weight-semibold">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder=""
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                  validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                              validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <Label className="form-label font-weight-semibold">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder=""
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                  validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                              validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Continue
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white">
                  Already have an account?{" "}
                  <Link to="/login" className="font-weight-medium text-blue">
                    Login
                  </Link>{" "}
                </p>
                <p className="text-white">© {new Date().getFullYear()} {AppConfiguration.REACT_APP_PRODUCT_NAME}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
