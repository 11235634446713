import {
  POST_DASHBOARD_MAIN_STATS,
  POST_DASHBOARD_MAIN_STATS_SUCCESS,
  POST_DASHBOARD_MAIN_STATS_ERROR,
  POST_DASHBOARD_MAIN_DETAIL_STATS,
  POST_DASHBOARD_MAIN_DETAIL_STATS_SUCCESS,
  POST_DASHBOARD_MAIN_DETAIL_STATS_ERROR,
  POST_DASHBOARD_DEVICE_TYPE_STATS,
  POST_DASHBOARD_DEVICE_TYPE_STATS_SUCCESS,
  POST_DASHBOARD_DEVICE_TYPE_STATS_ERROR,
  POST_DASHBOARD_BROWSER_TYPE_STATS,
  POST_DASHBOARD_BROWSER_TYPE_STATS_SUCCESS,
  POST_DASHBOARD_BROWSER_TYPE_STATS_ERROR,
  POST_DASHBOARD_DOMAIN_STATS,
  POST_DASHBOARD_DOMAIN_STATS_SUCCESS,
  POST_DASHBOARD_DOMAIN_STATS_ERROR,
  POST_DASHBOARD_STATE_STATS,
  POST_DASHBOARD_STATE_STATS_SUCCESS,
  POST_DASHBOARD_STATE_STATS_ERROR,
  POST_DASHBOARD_COMPARE_TO_MAIN_STATS_ERROR,
  POST_DASHBOARD_COMPARE_TO_MAIN_STATS_SUCCESS,
  POST_DASHBOARD_COMPARE_TO_MAIN_STATS,
} from './actionTypes';
import cloneDeep from 'lodash/cloneDeep';
const initial_main_data = {
  "average_duration": "0.00",
  "total_certificates": 0
}

const initialState = {
  main_stats: { ...initial_main_data },
  compare_to_main_stats: null,
  main_stats_loading: false,
  compare_to_main_stats_loading: false,
  main_stats_error: null,
  compare_to_main_stats_error: null,
  main_detail_stats: [],
  main_detail_stats_loading: false,
  main_detail_stats_error: null,
  device_type_stats: [],
  device_type_stats_loading: false,
  device_type_stats_error: null,
  browser_type_stats: [],
  browser_type_stats_loading: false,
  browser_type_stats_error: null,
  domain_stats: [],
  domain_stats_loading: false,
  domain_stats_error: null,
  state_stats: [],
  state_stats_loading: false,
  state_stats_error: null,
  stateData: [],
  stateDataLoading: false,
};
const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case POST_DASHBOARD_MAIN_STATS:
      return {
        ...state,
        main_stats_loading: true,
      };
    case POST_DASHBOARD_MAIN_STATS_SUCCESS:
      return {
        ...state,
        main_stats: { ...action.payload } || { ...initial_main_data },
        main_stats_loading: false,
      };
    case POST_DASHBOARD_MAIN_STATS_ERROR:
      return {
        ...state,
        main_stats_loading: false,
        main_stats_error: action.payload,
      };

    case POST_DASHBOARD_COMPARE_TO_MAIN_STATS:
      return {
        ...state,
        compare_to_main_stats_loading: true,
      };
    case POST_DASHBOARD_COMPARE_TO_MAIN_STATS_SUCCESS:
      return {
        ...state,
        compare_to_main_stats: { ...action.payload } || { ...initial_main_data },
        compare_to_main_stats_loading: false,
      };
    case POST_DASHBOARD_COMPARE_TO_MAIN_STATS_ERROR:
      return {
        ...state,
        compare_to_main_stats_loading: false,
        compare_to_main_stats_error: action.payload,
      };
    
    case POST_DASHBOARD_MAIN_DETAIL_STATS:
      return {
        ...state,
        main_detail_stats_loading: true,
      };
    case POST_DASHBOARD_MAIN_DETAIL_STATS_SUCCESS:
      return {
        ...state,
        main_detail_stats: Array.isArray(action.payload) ? [...action.payload] : [],
        main_detail_stats_loading: false,
      };
    case POST_DASHBOARD_MAIN_DETAIL_STATS_ERROR:
      return {
        ...state,
        main_detail_stats_loading: false,
        main_detail_stats_error: action.payload,
      };
    case POST_DASHBOARD_DEVICE_TYPE_STATS:
      return {
        ...state,
        device_type_stats_loading: true,
      };
    case POST_DASHBOARD_DEVICE_TYPE_STATS_SUCCESS:
      const clonedDeviceTypeStats = cloneDeep(action.payload) || [];
      return {
        ...state,
        device_type_stats: clonedDeviceTypeStats,
        device_type_stats_loading: false,
      };
    case POST_DASHBOARD_DEVICE_TYPE_STATS_ERROR:
      return {
        ...state,
        device_type_stats_loading: false,
        device_type_stats_error: action.payload,
      };
    case POST_DASHBOARD_BROWSER_TYPE_STATS:
      return {
        ...state,
        browser_type_stats_loading: true,
      };
    case POST_DASHBOARD_BROWSER_TYPE_STATS_SUCCESS:
      const clonedBrowserTypeStats = cloneDeep(action.payload) || [];
      return {
        ...state,
        browser_type_stats: clonedBrowserTypeStats,
        browser_type_stats_loading: false,
      };
    case POST_DASHBOARD_BROWSER_TYPE_STATS_ERROR:
      return {
        ...state,
        browser_type_stats_loading: false,
        browser_type_stats_error: action.payload,
      };
    case POST_DASHBOARD_DOMAIN_STATS:
      return {
        ...state,
        domain_stats_loading: true,
      };
    case POST_DASHBOARD_DOMAIN_STATS_SUCCESS:
      return {
        ...state,
        domain_stats: Array.isArray(action.payload) ? [...action.payload] : [],
        domain_stats_loading: false,
      };
    case POST_DASHBOARD_DOMAIN_STATS_ERROR:
      return {
        ...state,
        domain_stats_loading: false,
        domain_stats_error: action.payload,
      };
    case POST_DASHBOARD_STATE_STATS:
      return {
        ...state,
        state_stats_loading: true,
      };
    case POST_DASHBOARD_STATE_STATS_SUCCESS:
      return {
        ...state,
        state_stats: Array.isArray(action.payload) ? [...action.payload] : [],
        state_stats_loading: false,
      };
    case POST_DASHBOARD_STATE_STATS_ERROR:
      return {
        ...state,
        state_stats_loading: false,
        state_stats_error: action.payload,
      };
    default:
      return state;
  }
};

export default Dashboard;