import React from 'react';
import { Input, Label } from 'reactstrap';

const CheckBoxWithLabel = ({
    index,
    filter,
    handle_checkbox_value
}) => {

    return (
        <React.Fragment>
            <Input
                id={`custom-prospect-filter-${filter?.name}`}
                type="checkbox"
                name={filter?.name}
                value={!!filter?.is_active}
                defaultChecked={!!filter?.is_active}
                onChange={(e) => handle_checkbox_value(index, e.target.checked)}
            />
            <Label htmlFor={`custom-prospect-filter-${filter?.name}`} check>
                {filter?.label}
            </Label>
        </React.Fragment>
    )
}

export default CheckBoxWithLabel;
