import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReplayerComponent from '../../components/ReplayerComponent/ReplayerComponent';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { useTranslation } from 'react-i18next';
import i18n_keys from '../../i18n_keys';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { replayRequest } from 'store/replay/actions';
import './replayPage.css';

const ReplayPage = () => {
  const { t } = useTranslation();
  //make changes here
  const dispatch = useDispatch();
  const { certificateId } = useParams();
  const { search } = useLocation();
  const { width = 1024, height = 500, api_token } = Object.fromEntries(new URLSearchParams(search));
  const { eventsToShow, loading, error } = useSelector((state) => ({
    eventsToShow: state.replayReducer.data,
    loading: state.replayReducer.loading,
    error: state.replayReducer.error,
  }));
  const hasEventsToShow = eventsToShow && eventsToShow.length > 0;

  useEffect(() => {
    if (certificateId) {
      dispatch(replayRequest(certificateId,api_token));
    }
  }, [certificateId, dispatch]);

  return (
    <div>
      <div className='replay-page' style={{ background: 'white' }}>
        {loading ? (
          <LoadingSpinner />
        ) : error ? (
          <ErrorPage errorMsg={t(i18n_keys.ERROR_FETCHING_DATA)} />
        ) : hasEventsToShow ? (
          <ReplayerComponent events={eventsToShow} width={parseInt(width)} height={parseInt(height)} />
        ) : (
          <ErrorPage errorMsg={t(i18n_keys.NO_EVENTS_TO_DISPLAY)} />
        )}
      </div>
    </div>
  );
};

export default ReplayPage;
