// sagas.js
import { call, put, takeLatest } from 'redux-saga/effects';
import { currentUser } from '../../utils/Api';
import * as currentUserActions from './actionTypes';
import {
  currentUserFailure,
  currentUserSuccess
} from './actions';

export function* getCurrentUser(action) {
  try {
    const user = yield call(currentUser, action.payload);
    yield put(currentUserSuccess(user));
  } catch (error) {
    yield put(currentUserFailure(error.message));
  }
}


export default function* userSaga() {
  yield takeLatest(currentUserActions.CURRENT_USER_REQUEST, getCurrentUser);
}
