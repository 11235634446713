import '../../../assets/css/admin_user_filters_modal.css'
import React, { useEffect, useState } from 'react';
import { Modal, Form, ModalHeader, FormGroup } from "reactstrap";
import { useFormik } from "formik";
import FilterTypeComponent from './FilterTypes';
import CheckBoxWithLabel from './CheckBoxWithLabel';
import SearchFilters from './SearchBoxFilters';

const index = ({ isActive, modalState, filterItems = [], onSubmitModalState, onClickModalClose, isActiveSearchBox }) => {
    const [searchFilterInput, setSearchFilterInput] = useState('');

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { filters: filterItems },
        onSubmit: (values) => {
            onSubmitModalState(values?.filters);
        }
    });

    useEffect(() => {
    }, [validation.values]);

    const handleFiltersCheckbox = (index, value) => {
        const field = 'is_active';
        validation.setFieldValue(`filters.${index}.${field}`, value);
    };

    const handleFilterValues = (index, value) => {
        const field = 'value';
        validation.setFieldValue(`filters.${index}.${field}`, value);
    };

    const handleFilterComparatorValues = (index, value) => {
        const field = 'comparator_value';
        validation.setFieldValue(`filters.${index}.${field}`, value);
    };

    return (
        <Modal className="filter admin-filter-users"
            isOpen={isActive}
            backdrop={true}
            fade={false}
            backdropClassName="no-backdrop-color"
            toggle={onClickModalClose}
            scrollable={true}
        >
            <ModalHeader>
                <span className="modal-title text-black">Filters</span>
                <button type="button" className="close" onClick={onClickModalClose} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </ModalHeader>
            <div className="cc-filters-position-relative">
                <Form onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                }}>
                    <div className="m-3" style={{ minHeight: '50vh' }}>
                        {isActiveSearchBox && (
                            <SearchFilters value={searchFilterInput} handle_set_field_value={setSearchFilterInput} name="search_filter" placeholder="Search Filters" />
                        )}

                        {(validation?.values?.filters || []).map((filter, index) => {
                            return (
                                <FormGroup key={index} className="mt-2" id="user-received" check>
                                    <CheckBoxWithLabel
                                        filter={filter}
                                        index={index}
                                        handle_checkbox_value={handleFiltersCheckbox} />
                                    <div className="my-2">
                                        {filter?.is_active && (
                                            <FilterTypeComponent
                                                filter={filter}
                                                index={index}
                                                handle_filter_values={handleFilterValues}
                                                handle_filter_comparator_values={handleFilterComparatorValues}
                                            />
                                        )}
                                    </div>
                                </FormGroup>
                            )
                        })}
                    </div>
                    <div className="modal-footer d-flex flex-wrap bg-white sf-admin-filters-position-sticky gap-2 pt-3">
                        <button className={`btn  btn-sm btn-primary`} type="submit">Update</button>
                        <button className="btn btn-light btn-sm" type="reset" onClick={onClickModalClose}>Cancel</button>
                    </div>
                </Form>
            </div>
        </Modal>
    )
}

export default index;