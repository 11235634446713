// import { FILTER_COMPONENT_TYPES, REPORTING_TIMEZONE } from 'helpers/sf_constants';
import React from 'react';
import TextInputWithLabel from './TextInputWithLabel';
import SelectBoxWithLabel from './SelectBoxWithLabel';
import DOBInputWithLabel from './DOBInputWithLabel';
import ComparatorWithLabel from './ComparatorWithLabel';
import { FILTER_COMPONENT_TYPES, REPORTING_TIMEZONE } from 'helpers/constants';

const index = (props) => {

    switch (props?.filter?.component_type) {
        case FILTER_COMPONENT_TYPES.TEXT:
        case FILTER_COMPONENT_TYPES.EMAIL:
            return (
                <TextInputWithLabel {...props} />
            )
        case FILTER_COMPONENT_TYPES.DATE_RANGE:
            return (
                <DOBInputWithLabel {...props} timezone={REPORTING_TIMEZONE} />
            )
        case FILTER_COMPONENT_TYPES.SINGLE_SELECT:
            return (
                <SelectBoxWithLabel {...props} is_multi={false} />
            )
        case FILTER_COMPONENT_TYPES.MULTI_SELECT:
            return (
                <SelectBoxWithLabel {...props} is_multi={true} />
            )
        case FILTER_COMPONENT_TYPES.COMPARATOR:
            return (
                <ComparatorWithLabel {...props} is_multi={false}  />
            )
        default:
            return (
                <TextInputWithLabel {...props} />
            )
    }
}

export default index;
